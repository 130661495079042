import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "context-menu--btns" }
const _hoisted_2 = { class: "context-menu--levels" }
const _hoisted_3 = { class: "context-menu--btns-main context-menu--level-1" }
const _hoisted_4 = { class: "context-menu--btns-main context-menu--level-2" }

import { ref, Ref, PropType } from 'vue';

import { Store } from '@/store';
import FavoriteService from '@/services/Favorite';
import ShareService from '@/services/Share';
import Song from '@/types/song';

export default /*@__PURE__*/_defineComponent({
  __name: 'Context',
  props: {
    song: Object as PropType<Song>,
},
  emits: ['hideContext'],
  setup(__props, { emit: __emit }) {

const store = Store.getInstance();

const showAuthorMenu: Ref<boolean> = ref(false);

const props = __props;

const emit = __emit;

function favoriteLabel() {
    if (isFavorite(Number(props.song?.number))) {
        return 'Avynda';
    }
    return 'Ynda';
}

function isFavorite(number: any) {
    if (!number) {
        return false;
    }

    return store.favorites.value.includes(Number(number));
}

function hideContextMenu() {
    emit('hideContext', null);

    showAuthorMenu.value = false;
}

function favourite() {
    hideContextMenu();
    if (FavoriteService.isFavorite(Number(props.song?.number))) {
        return FavoriteService.remove(Number(props.song?.number));
    }

    FavoriteService.add(Number(props.song?.number));
}

async function share() {
    if (props.song) {
        ShareService.shareSong(props.song);
    }
}

function clickAway(e: any) {
    const target = e.target;

    if (target.classList.contains?.('context-menu--levels')) {
        hideContextMenu();
    }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["context-menu", { active: props.song, 'level-2': showAuthorMenu.value }]),
    onClick: clickAway
  }, [
    _createElementVNode("div", {
      class: "overlay",
      onClick: hideContextMenu
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "context-menu--btn font:sans-serif",
            onClick: favourite
          }, _toDisplayString(favoriteLabel()), 1),
          _createElementVNode("div", {
            class: "context-menu--btn font:sans-serif",
            onClick: share
          }, " Deil sang "),
          _withDirectives(_createVNode(_component_router_link, {
            class: "context-menu--btn font:sans-serif",
            onClick: hideContextMenu,
            to: `/song/${__props.song?.number}`
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" Vís akkordir ")
            ])),
            _: 1
          }, 8, ["to"]), [
            [_vShow, __props.song?.chords]
          ]),
          _createElementVNode("div", {
            class: "context-menu--btn font:sans-serif chevron",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (showAuthorMenu.value = true))
          }, " Yrkjarar, týðarar og tónaskøld ")
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "context-menu--btn font:sans-serif chevron reverse",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (showAuthorMenu.value = false))
          }, " Aftur "),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.song?.authors, (author, index) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              key: index,
              class: "context-menu--btn font:sans-serif",
              onClick: hideContextMenu,
              to: `/menu/index/people/${author?.id}?from=song-${__props.song?.number}`
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(author?.first_name || '') + " " + _toDisplayString(author?.last_name || ''), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", {
        class: "context-menu--btn context-menu--btn-close font:sans-serif",
        onClick: hideContextMenu
      }, " Lat aftur ")
    ])
  ], 2))
}
}

})