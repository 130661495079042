import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import Chords from '../views/Chords.vue';
import Container from '../views/Container.vue';
import Error from '../views/Error.vue';
import List from '../views/List.vue';
import About from '../views/Menu/About.vue';
import Menu from '../views/Menu/Menu.vue';
import Privacy from '../views/Menu/Privacy.vue';
import Rights from '../views/Menu/Rights.vue';
import NoConnection from '../views/NoConnection.vue';
import Search from '../views/Search.vue';
import Songs from '../views/Songs.vue';

import IndexPeople from '../views/Index/IndexPeople.vue';
import IndexPeopleSongs from '../views/Index/IndexPeopleSongs.vue';
import IndexSections from '../views/Index/IndexSections.vue';
import IndexSectionsSongs from '../views/Index/IndexSectionsSongs.vue';
import IndexSongs from '../views/Index/IndexSongs.vue';
import Favorite from '../views/Menu/Favorite.vue';
import Index from '../views/Menu/Index.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/list',
    },
    {
        path: '/',
        name: 'Container',
        component: Container,
        meta: {
            slideValue: 2,
        },
        children: [
            {
                path: '/list',
                name: 'List',
                component: List,
                meta: {
                    slideValue: 1,
                },
            },
            {
                path: '/songs',
                name: 'Songs',
                component: Songs,
                meta: {
                    slideValue: 2,
                    link: 'List',
                },
            },
            {
                path: '/song/:id',
                name: 'Chords',
                component: Chords,
                meta: {
                    slideValue: 3,
                    link: 'Songs',
                },
            },
            {
                path: '/search',
                name: 'Search',
                component: Search,
                meta: {
                    slideValue: 4,
                },
            },
            {
                path: '/menu',
                name: 'Menu',
                component: Menu,
                meta: {
                    slideValue: 5,
                },
            },
            {
                path: '/menu/index',
                name: 'Index',
                component: Index,
                meta: {
                    slideValue: 6,
                    link: 'Menu',
                },
            },
            {
                path: '/menu/index/songs',
                name: 'IndexSongs',
                component: IndexSongs,
                meta: {
                    slideValue: 7,
                    link: 'Menu',
                },
            },
            {
                path: '/menu/index/sections',
                name: 'IndexSections',
                component: IndexSections,
                meta: {
                    slideValue: 8,
                    link: 'Menu',
                },
            },
            {
                path: '/menu/index/sections/:id',
                name: 'IndexSectionsSongs',
                component: IndexSectionsSongs,
                meta: {
                    slideValue: 9,
                    link: 'IndexSections',
                },
            },
            {
                path: '/menu/index/people',
                name: 'IndexPeople',
                component: IndexPeople,
                meta: {
                    slideValue: 10,
                    link: 'Menu',
                },
            },
            {
                path: '/menu/index/people/:id',
                name: 'IndexPeopleSongs',
                component: IndexPeopleSongs,
                meta: {
                    slideValue: 11,
                    link: 'IndexPeople',
                },
            },
            {
                path: '/menu/favorite',
                name: 'Favorite',
                component: Favorite,
                meta: {
                    slideValue: 12,
                    link: 'Menu',
                },
            },
            {
                path: '/menu/about',
                name: 'About',
                component: About,
                meta: {
                    slideValue: 13,
                    link: 'Menu',
                },
            },
            {
                path: '/menu/rights',
                name: 'Rights',
                component: Rights,
                meta: {
                    slideValue: 14,
                    link: 'Menu',
                },
            },
            {
                path: '/menu/privacy',
                name: 'Privacy',
                component: Privacy,
                meta: {
                    slideValue: 15,
                    link: 'Menu',
                },
            },
        ],
    },
    {
        path: '/no-connection',
        name: 'No Connection',
        component: NoConnection,
        meta: {
            slideValue: 99,
        },
    },
    {
        path: '/error',
        name: 'Error',
        component: Error,
        meta: {
            slideValue: 100,
        },
    },
];

declare module 'vue-router' {
    interface RouteMeta {
        slideValue: number;
        link?: string;
    }
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to) => {
    if (to.name === 'Container') {
        return { name: 'List' };
    }
});

router.afterEach((to, from) => {
    if (to.meta.slideValue < from.meta.slideValue) {
        to.meta.transitionName = 'slide-left';
    }

    if (to.meta.slideValue > from.meta.slideValue) {
        to.meta.transitionName = 'slide-right';
    }
});

export default router;
