<script lang="ts" setup>
import SectionLinks from '@/components/SectionLinks.vue';
import Section from '@/types/section';

import { session } from '@/store';
const sections: Section[] | null = session.sections;
</script>

<template>
    <main class="index-sections">
        <section class="title-with-back px:30 border-bottom max-width">
            <router-link
                :to="{ name: 'Index' }"
                class="back-icon"
            >
                <div></div>
            </router-link>
            <h1>Deildir</h1>
        </section>

        <section-links
            class="max-width"
            :sections="sections"
        />
    </main>
</template>
