import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "index-sections" }
const _hoisted_2 = { class: "title-with-back px:30 border-bottom max-width" }

import SectionLinks from '@/components/SectionLinks.vue';
import Section from '@/types/section';

import { session } from '@/store';

export default /*@__PURE__*/_defineComponent({
  __name: 'IndexSections',
  setup(__props) {

const sections: Section[] | null = session.sections;

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: { name: 'Index' },
        class: "back-icon"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("div", null, null, -1)
        ])),
        _: 1
      }),
      _cache[1] || (_cache[1] = _createElementVNode("h1", null, "Deildir", -1))
    ]),
    _createVNode(SectionLinks, {
      class: "max-width",
      sections: _unref(sections)
    }, null, 8, ["sections"])
  ]))
}
}

})