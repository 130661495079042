import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "px:30 border-bottom max-width" }
const _hoisted_3 = { class: "links song-links max-width font:sans-serif" }

import { Ref, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import SongListItem from '@/components/SongListItem.vue';

import Songs from '@/types/songs';
import Song from '@/types/song';
import Author from '@/types/author';

import { session } from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'IndexPeopleSongs',
  setup(__props) {

const route = useRoute();
const songs: Songs | null = session.songs;
const authors: Author[] | null = session.authors;

const author: Ref<Author | null> = ref(null);
const authorSongs: Ref<Song[]> = ref([]);
const loading: Ref<boolean> = ref(true);

onMounted(() => {
    if (route.params.id) {
        const findAuthor = authors?.find((findAuthor: Author) => findAuthor.id === Number(route.params.id));

        if (findAuthor && songs) {
            author.value = findAuthor;

            const findAuthorSongs: Song[] = [];

            findAuthor.songs.forEach((song: number) => {
                findAuthorSongs.push(songs.general[song - 1]);
            });

            authorSongs.value = findAuthorSongs;
        }
    }

    setTimeout(() => {
        loading.value = false;
    }, 200);
});

return (_ctx: any,_cache: any) => {
  return (author.value)
    ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
        _createElementVNode("section", _hoisted_2, [
          _createElementVNode("h1", null, _toDisplayString(author.value.key), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(authorSongs.value, (song, index) => {
            return (_openBlock(), _createBlock(SongListItem, {
              key: `song${index}`,
              song: song
            }, null, 8, ["song"]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})