<script lang="ts" setup>
import { onMounted, Ref, ref } from 'vue';

import IndexSong from '@/components/IndexSong.vue';

import Songs from '@/types/songs';

import { session } from '@/store';

type Title = {
    is_choral: boolean;
    number: number;
    title: string;

    type: string;
};

// get each title of every song
const songs: Songs | null = session.songs;
const titles: Ref<Title[]> = ref([]);

onMounted(() => {
    if (!songs) {
        return;
    }

    for (const song of songs.general) {
        for (const title of song.titles) {
            titles.value.push({
                ...title,
                number: song.number,
            });
        }
    }

    titles.value.sort((a, b) => (a.title > b.title ? 1 : -1));
});
</script>

<template>
    <main class="index-songs">
        <section class="px:30 border-bottom max-width">
            <h1>
                <router-link
                    :to="{ name: 'Index' }"
                    class="back-icon"
                >
                    <div></div>
                </router-link>
                Yvirlit yvir sangir, niðurløg og kór
            </h1>

            <p class="index-paragraph font:sans-serif">
                Byrjanarreglurnar eru skrivaðar við vanligari skrift, niðurløgini við skráskrift, og kórini eru merkt við einum (k)
            </p>
        </section>

        <div class="links song-links max-width font:sans-serif">
            <template
                v-for="(song, index) in titles"
                :key="`song-${index}`"
            >
                <index-song :song="song" />
            </template>
        </div>
    </main>
</template>

<style lang="scss" scoped>
.index-paragraph {
    margin-bottom: 2rem;
    line-height: 150%;
}

h1 {
    .back-icon {
        display: none;
    }
}

@media (min-width: 992px) {
    h1 {
        position: relative;
        padding-left: 4.1rem;
        .back-icon {
            display: flex;
            position: absolute;
            left: 0;
            top: -0.3rem;
        }
    }
    .index-paragraph {
        max-width: 32rem;
        padding-left: 4.1rem;
    }
}
</style>
