<script lang="ts" setup>
import { PropType, defineProps, defineEmits } from 'vue';
import Song from '@/types/song';
import { Store } from '@/store';

const store = Store.getInstance();

const props = defineProps({
    song: Object as PropType<Song>,
    chords: Boolean,
});

const emit = defineEmits(['context']);

function isFavorite(number: any) {
    if (!number) {
        return false;
    }

    return store.favorites.value.includes(Number(number));
}

function openContextMenu(number: any) {
    emit('context', number);
}
</script>

<template>
    <div class="song px:30">
        <div
            class="song-top mb:10"
            @click="openContextMenu(props.song?.number)"
        >
            <div class="song-meta-container">
                <div class="song-number">{{ props.song?.number }}</div>

                <div class="song-meta">{{ props.song?.meta }}</div>
            </div>

            <div class="song-context">
                <div
                    class="song-context--like-btn icon"
                    v-if="isFavorite(props.song?.number)"
                ></div>

                <div class="song-context--menu-btn"></div>
            </div>
        </div>

        <div
            class="song-content"
            :class="{ 'font:mono': chords }"
        >
            <template v-if="!chords">
                <div
                    v-for="(verse, index) in song?.verses"
                    :key="`verse${index}`"
                    :class="verse.type"
                >
                    {{ verse.text }}
                </div>
            </template>

            <template v-if="chords && props.song?.chords">
                <div v-html="props.song?.chords"></div>
            </template>
        </div>
    </div>
</template>
