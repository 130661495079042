<script lang="ts" setup>
import { Ref, ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import Fuse from 'fuse.js';

import AuthorLinks from '@/components/AuthorLinks.vue';

import Author from '@/types/author';

import { session } from '@/store';

const fuseOptions = {
    threshold: 0.2,
    distance: Infinity,
    includeScore: true,
    shouldSort: true,
    minMatchCharLength: 3,
    keys: ['lastname', 'firstname', 'key'],
};

const route = useRoute();
const isSearching: Ref<boolean> = ref(false);

const authors: Author[] | null = session.authors;

const hits: Ref<any[]> = ref([]);
const query: Ref<string> = ref('');
const loading: Ref<boolean> = ref(false);

const fuse = new Fuse([], fuseOptions);

onMounted(() => {
    fuse.setCollection(authors as any);
});

watch(route, () => {
    setTimeout(() => {
        isSearching.value = false;
        query.value = '';
        hits.value = [];
    }, 1000);
});

/**
 * Whenever 'query' changes, (fuzzy) search for songs
 */
let timeout = 0;
watch(query, (value) => {
    // debounce
    clearTimeout(timeout);

    loading.value = true;
    timeout = setTimeout(() => {
        hits.value = [];

        value = value.replace(' ', '|');

        const results = fuse.search(value);

        hits.value = results;
        loading.value = false;
    }, 250);
});

function shouldBlur() {
    if (query.value.length !== 0) {
        return;
    }

    isSearching.value = false;
}

function clearQuery() {
    query.value = '';
    isSearching.value = false;
}
</script>

<template>
    <main class="index-sections">
        <section class="index-wrapper px:30 border-bottom max-width">
            <router-link
                :to="{ name: 'Index' }"
                class="back-icon"
                :class="{ searching: isSearching }"
            >
                <div></div>
            </router-link>
            <h1
                class="transition"
                :class="{ hidden: isSearching }"
            >
                Yrkjarar, týðarar og tónaskøld
            </h1>

            <p
                class="transition people-paragraph font:sans-serif"
                :class="{ hidden: isSearching }"
            >
                Niðanfyri eru yrkjarar, týðarar og tónaskøld í bókstavarað eftir eftirnavni savnað í sama yvirliti. Her er ikki útgreinað, um talan er
                um yrking, týðing ella lag, men bert í hvørjum sangum viðkomandi persónur eigur lut. Oman fyri sangirnar inni í bókini sæst, hvør
                eigur orð, lag ella týðing. Teknið * merkir orð, og teknið # merkir lag.
            </p>

            <div class="max-width search-input">
                <input
                    type="text"
                    @focus="isSearching = true"
                    @blur="shouldBlur"
                    placeholder="Leita..."
                    v-model="query"
                />

                <div
                    v-if="query.length === 0"
                    class="icon:search"
                ></div>
                <div
                    @click="clearQuery"
                    v-if="query.length > 0"
                    class="icon:close"
                ></div>
            </div>
        </section>

        <author-links
            class="max-width"
            :authors="query.length > 2 ? hits : authors"
        />

        <p
            v-if="query.length > 2 && !hits?.length && !loading"
            class="my:40 px:45 text:center text:sm max-width font:sans-serif"
        >
            Onki varð funnið
        </p>
    </main>
</template>

<style lang="scss" scoped>
@import '@/scss/mixins';

.index-wrapper {
    position: relative;
    .back-icon {
        display: none;

        @media (min-width: 992px) {
            display: flex;
            position: absolute;
            left: 0;
            top: -0.3rem;
            transition: 0.2s ease;

            &.searching {
                transform: translate(-160%, 0.85rem);
            }
        }
    }
}

h1 {
    @media (min-width: 992px) {
        margin-left: 4.1rem;
    }
}

.people-paragraph {
    line-height: 150%;
    margin-bottom: 2rem;

    @media (min-width: 992px) {
        margin-left: 4.1rem;
        max-width: 32rem;
    }
}

.transition {
    transition: 0.1s ease;
}

.hidden {
    max-height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
}

.search-input {
    position: relative;
    margin-bottom: 2rem;

    [class*='icon\:'] {
        display: block;

        position: absolute;
        top: 0;
        right: 0.375rem;
        width: 3.75rem;
        height: 3.75rem;

        background: #8a8553;

        transition: 0.2s background ease;

        &:hover {
            background: darken(#8a8553, 10%);
        }
    }

    .icon\:search {
        @include mask('../../assets/icon-search.svg', 1.25rem 1.25rem);
        pointer-events: none;
    }

    .icon\:close {
        @include mask('../../assets/icon-close.svg', 1.25rem 1.25rem);
    }
}
</style>
