<script lang="ts" setup>
import { defineProps } from 'vue';

const props = defineProps({
    sections: Object as any,
});
</script>

<template>
    <div class="links font:sans-serif">
        <router-link
            v-for="(section, index) in props.sections"
            :key="`section-${index}`"
            :to="`/menu/index/sections/${section.id}`"
        >
            {{ section.title }}
        </router-link>
    </div>
</template>
