import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "container" }

import VHeader from '@/components/VHeader.vue';
import VFooter from '@/components/VFooter.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'Container',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(VHeader),
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component, route }) => [
          _createVNode(_Transition, {
            name: route.meta.transitionName
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_KeepAlive, null, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                  key: route.path
                }))
              ], 1024))
            ]),
            _: 2
          }, 1032, ["name"])
        ]),
        _: 1
      }),
      _createVNode(VFooter)
    ])
  ]))
}
}

})