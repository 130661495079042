import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "index" }
const _hoisted_2 = { class: "fontSize-container max-width" }
const _hoisted_3 = { class: "font-resizer" }
const _hoisted_4 = { class: "app-info max-width" }

import Links from '@/components/Links.vue';

import { session, Store } from '@/store';

export default /*@__PURE__*/_defineComponent({
  __name: 'Menu',
  setup(__props) {

const store = Store.getInstance();

const version = process.env.VUE_APP_VERSION;

const links = [
    // { title: 'Innihaldsyvirlit', url: '/menu/index' },
    { title: 'Yrkjarar, týðarar og tónaskøld', url: '/menu/index/people' },
    { title: 'Deildir', url: '/menu/index/sections' },
    { title: 'Yvirlit yvir sangir, niðurløg og kór', url: '/menu/index/songs' },
    { title: 'Yndissangir', url: '/menu/favorite' },
    // { title: 'Um appina', url: '/menu/about' },
    { title: 'Rættindi', url: '/menu/rights' },
    { title: 'Privatlívspolitikkur', url: '/menu/privacy' },
];

const html = document.querySelector('html');

function setFontSize(newSize: number) {
    if (session.fontSize === newSize) {
        return;
    }

    session.fontSize = newSize;
    store.fontSize.value = newSize;

    if (html) {
        html.style.fontSize = `${session.fontSize}px`;
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("section", { class: "border-bottom max-width px:30" }, [
      _createElementVNode("h1", null, "Valmynd")
    ], -1)),
    _createVNode(Links, {
      class: "max-width",
      links: links
    }),
    _createElementVNode("section", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("label", {
        for: "fontSize",
        class: "font:serif weight:700"
      }, "Skriftstødd", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["calc font:sans-serif", { active: _unref(session).fontSize === 16 }]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (setFontSize(16)))
        }, " Vanlig ", 2),
        _createElementVNode("div", {
          class: _normalizeClass(["calc font:sans-serif", { active: _unref(session).fontSize === 18 || _unref(session).fontSize == 20 }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (setFontSize(18)))
        }, " Stór ", 2)
      ])
    ]),
    _createElementVNode("section", _hoisted_4, [
      _createElementVNode("span", null, "Útgáva " + _toDisplayString(_unref(version)), 1)
    ])
  ]))
}
}

})