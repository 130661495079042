import axios from 'axios';
import _sortBy from 'lodash/sortBy';

import Author from '@/types/author';
import { Network } from '@capacitor/network';
import { Preferences } from '@capacitor/preferences';

import { DB, session } from '@/store';
import router from '@/router';
const db = DB.getInstance();

export default class AuthorService {
    public static async getAuthors(): Promise<Author[] | null> {
        // check if the device has a connection to the internet
        const status = await Network.getStatus();
        const isConnected = status.connected;

        // last sync time
        const lastSyncTime = await Preferences.get({
            key: 'authors_time',
        });

        let authors: Author[] | null = null;

        // :: NO LOCAL AUTHORS AND NO INTERNET CONNECTION
        if (!lastSyncTime.value && !isConnected) {
            router.push('/no-connection');
            return null;
        }

        // :: FIRST TIME SYNC
        if (!lastSyncTime.value && isConnected) {
            await AuthorService.getAuthorsFromAPI(lastSyncTime.value).then(async (authorsFromAPI) => {
                if (authorsFromAPI) {
                    await db.saveAuthors(authorsFromAPI);
                    authors = authorsFromAPI;
                }
            });
        }

        // :: CHECK IF API HAS UPDATES
        if (lastSyncTime.value) {
            await AuthorService.getAuthorsFromAPI(lastSyncTime.value).then(async (authorsFromAPI) => {
                if (authorsFromAPI) {
                    await db.updateAuthors(authorsFromAPI).then(async () => {
                        console.log('Authors updated');
                    });

                    await db.getAuthors().then(async (res) => {
                        const authorsSorted = _sortBy(res, ['key']);
                        authors = authorsSorted;
                    });
                }
            });
        }

        // :: GET LOCAL AUTHORS
        if (lastSyncTime.value) {
            await db.getAuthors().then(async (res) => {
                if (res) {
                    const authorsSorted = _sortBy(res, ['key']);
                    authors = authorsSorted;
                }
            });
        }

        if (authors) {
            session.authors = authors;
        }

        return authors;
    }

    /**
     *
     * @param lastSyncTime
     * @returns
     */
    private static async getAuthorsFromAPI(lastSyncTime: string | null): Promise<Author[] | null> {
        let authorsFromAPI: Author[] | null = null;
        try {
            const customConfig = {
                auth: {
                    username: process.env.VUE_APP_API_USERNAME,
                    password: process.env.VUE_APP_API_PASSWORD,
                },
            };

            await axios
                .get(`${process.env.VUE_APP_BASE_API}/wp-json/v1/authors?time=${lastSyncTime || 0}`, customConfig)
                .then(async (response) => {
                    if (!response.data?.length) {
                        return;
                    }

                    await Preferences.set({
                        key: 'authors_time',
                        value: JSON.stringify(Math.floor(Date.now() / 1000)),
                    });

                    await AuthorService.filterAuthorsByKey(response.data).then((sortedAuthors) => {
                        authorsFromAPI = sortedAuthors;
                    });

                    return authorsFromAPI;
                })
                .catch((error) => {
                    console.error(error.response);
                });
        } catch {
            console.log('catch');
        }

        return authorsFromAPI;
    }

    /**
     *
     * @param authors
     * @returns
     */
    private static async filterAuthorsByKey(authors: Author[]): Promise<Author[] | null> {
        let sorted: Author[] | null = null;

        // if there are already authors in storage, we merge the array with the data from the server
        if (authors.length) {
            // filter out the authors that are updated
            const filteredAuthors = authors.filter(function (entry) {
                return !authors.some(function (elm: Author) {
                    return entry.id === elm.id;
                });
            });

            // merge filtered authors and the authors from the server
            sorted = [...filteredAuthors, ...authors];
        }

        return _sortBy(sorted, ['key']);
    }
}
