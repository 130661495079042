<script lang="ts" setup>
import { Ref, ref, onMounted, watch } from 'vue';

import SongListItem from '@/components/SongListItem.vue';

import Songs from '@/types/songs';
import Song from '@/types/song';

import { Store, session } from '@/store';
const store = Store.getInstance();

const songs: Songs | null = session.songs;
const favouriteSongs: Ref<Song[]> = ref([]);

onMounted(() => {
    if (store.favorites.value.length > 0) {
        getFavorites(store.favorites.value);
    }
});

watch(store.favorites, (favorites) => {
    getFavorites(favorites);
});

function getFavorites(favorites: number[]) {
    favouriteSongs.value = [];

    favorites.forEach((favorite) => {
        const song = songs?.general.find((song) => Number(song.number) === favorite);

        if (song) {
            favouriteSongs.value.push(song);
        }
    });

    favouriteSongs.value.reverse();
}
</script>

<template>
    <main class="index-sections">
        <section
            class="title-with-back px:30 max-width"
            :class="{ 'border-bottom': favouriteSongs.length > 0 }"
        >
            <router-link
                :to="{ name: 'Menu' }"
                class="back-icon"
            >
                <div></div>
            </router-link>
            <h1>Yndissangir</h1>
        </section>

        <div
            v-if="favouriteSongs.length > 0"
            class="links song-links max-width font:sans-serif"
        >
            <song-list-item
                v-for="(song, index) in favouriteSongs"
                :key="`song${index}`"
                :song="song"
            />
        </div>

        <div
            v-if="favouriteSongs.length === 0"
            class="no-songs max-width font:sans-serif"
        >
            <p>Tú hevur ikki yndað nakran sang. Fyri at ynda sangir, skalt tú bert trýsta á nummarið á sanginum og velja “Ynda”.</p>
        </div>
    </main>
</template>

<style lang="scss" scoped>
.no-songs {
    line-height: 150%;
    margin: 0;
    padding: 0 2rem;

    @media (min-width: 992px) {
        p {
            max-width: 26rem;
            margin-left: 4.1rem;
        }
    }
}
</style>
