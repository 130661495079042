import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref } from "vue"

const _hoisted_1 = { class: "index-sections" }
const _hoisted_2 = { class: "index-wrapper px:30 border-bottom max-width" }
const _hoisted_3 = { class: "max-width search-input" }
const _hoisted_4 = {
  key: 0,
  class: "icon:search"
}
const _hoisted_5 = {
  key: 0,
  class: "my:40 px:45 text:center text:sm max-width font:sans-serif"
}

import { Ref, ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import Fuse from 'fuse.js';

import AuthorLinks from '@/components/AuthorLinks.vue';

import Author from '@/types/author';

import { session } from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'IndexPeople',
  setup(__props) {

const fuseOptions = {
    threshold: 0.2,
    distance: Infinity,
    includeScore: true,
    shouldSort: true,
    minMatchCharLength: 3,
    keys: ['lastname', 'firstname', 'key'],
};

const route = useRoute();
const isSearching: Ref<boolean> = ref(false);

const authors: Author[] | null = session.authors;

const hits: Ref<any[]> = ref([]);
const query: Ref<string> = ref('');
const loading: Ref<boolean> = ref(false);

const fuse = new Fuse([], fuseOptions);

onMounted(() => {
    fuse.setCollection(authors as any);
});

watch(route, () => {
    setTimeout(() => {
        isSearching.value = false;
        query.value = '';
        hits.value = [];
    }, 1000);
});

/**
 * Whenever 'query' changes, (fuzzy) search for songs
 */
let timeout = 0;
watch(query, (value) => {
    // debounce
    clearTimeout(timeout);

    loading.value = true;
    timeout = setTimeout(() => {
        hits.value = [];

        value = value.replace(' ', '|');

        const results = fuse.search(value);

        hits.value = results;
        loading.value = false;
    }, 250);
});

function shouldBlur() {
    if (query.value.length !== 0) {
        return;
    }

    isSearching.value = false;
}

function clearQuery() {
    query.value = '';
    isSearching.value = false;
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: { name: 'Index' },
        class: _normalizeClass(["back-icon", { searching: isSearching.value }])
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("div", null, null, -1)
        ])),
        _: 1
      }, 8, ["class"]),
      _createElementVNode("h1", {
        class: _normalizeClass(["transition", { hidden: isSearching.value }])
      }, " Yrkjarar, týðarar og tónaskøld ", 2),
      _createElementVNode("p", {
        class: _normalizeClass(["transition people-paragraph font:sans-serif", { hidden: isSearching.value }])
      }, " Niðanfyri eru yrkjarar, týðarar og tónaskøld í bókstavarað eftir eftirnavni savnað í sama yvirliti. Her er ikki útgreinað, um talan er um yrking, týðing ella lag, men bert í hvørjum sangum viðkomandi persónur eigur lut. Oman fyri sangirnar inni í bókini sæst, hvør eigur orð, lag ella týðing. Teknið * merkir orð, og teknið # merkir lag. ", 2),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          onFocus: _cache[0] || (_cache[0] = ($event: any) => (isSearching.value = true)),
          onBlur: shouldBlur,
          placeholder: "Leita...",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((query).value = $event))
        }, null, 544), [
          [_vModelText, query.value]
        ]),
        (query.value.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4))
          : _createCommentVNode("", true),
        (query.value.length > 0)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              onClick: clearQuery,
              class: "icon:close"
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(AuthorLinks, {
      class: "max-width",
      authors: query.value.length > 2 ? hits.value : _unref(authors)
    }, null, 8, ["authors"]),
    (query.value.length > 2 && !hits.value?.length && !loading.value)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, " Onki varð funnið "))
      : _createCommentVNode("", true)
  ]))
}
}

})