import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "song-link--name" }
const _hoisted_2 = { class: "song-link--number" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "number" }

import { PropType, computed } from 'vue';

import Title from '@/types/title';
import Song from '@/types/song';


export default /*@__PURE__*/_defineComponent({
  __name: 'SongListItem',
  props: {
    song: Object as PropType<Song>,
},
  setup(__props) {

const props = __props;

const isChoral = computed(() => {
    let returnValue = false;
    props.song?.titles.forEach((title: Title) => {
        if (title.is_choral) {
            returnValue = true;
        }
    });

    return returnValue;
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (props.song?.type === 'song')
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: `/songs?song=${props.song?.number}`
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(props.song?.titles[0].title), 1),
          _createElementVNode("div", _hoisted_2, [
            (isChoral.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, "(k)"))
              : _createCommentVNode("", true),
            _cache[0] || (_cache[0] = _createTextVNode()),
            _createElementVNode("span", _hoisted_4, _toDisplayString(props.song?.number), 1)
          ])
        ]),
        _: 1
      }, 8, ["to"]))
    : _createCommentVNode("", true)
}
}

})