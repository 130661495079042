<script lang="ts" setup>
import { PropType, defineProps } from 'vue';

const props = defineProps({
    links: Object as PropType<
        {
            url: string;
            title: string;
        }[]
    >,
});
</script>

<template>
    <div class="links font:sans-serif">
        <router-link
            v-for="(link, index) in props.links"
            :key="`link${index}`"
            :to="link.url"
        >
            {{ link.title }}
        </router-link>
    </div>
</template>
