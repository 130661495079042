<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const path = computed(() => route.path);
</script>

<template>
    <footer>
        <router-link
            to="/list"
            class="logo"
            >NÝGGJA<br />SANGBÓK</router-link
        >

        <router-link
            to="/list"
            class="font:sans-serif text:xs"
            :class="{ active: path.startsWith('/list') }"
        >
            <div class="icon:index" />
            Yvirlit
        </router-link>

        <router-link
            to="/songs"
            class="font:sans-serif text:xs"
            :class="{ active: path.startsWith('/song') }"
        >
            <div class="icon:songs" />
            Sangir
        </router-link>

        <router-link
            to="/search"
            class="font:sans-serif text:xs"
            :class="{ active: path.startsWith('/search') }"
        >
            <div class="icon:search" />
            Leita
        </router-link>

        <router-link
            to="/menu"
            class="font:sans-serif text:xs"
            :class="{ active: path.startsWith('/menu') }"
        >
            <div class="icon:menu" />
            Meira
        </router-link>
    </footer>
</template>

<style lang="scss" scoped>
@import '@/scss/mixins';

footer {
    border-top: 1px solid var(--color-border);
    padding-bottom: calc(env(safe-area-inset-bottom) - 1rem) !important;
    display: flex;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: var(--color-background);
    display: flex;
    justify-content: space-between;

    .logo {
        width: 5.375rem;
        height: 5.375rem;
        background-color: var(--color-dark);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-gold);
        text-align: center;
        line-height: 1.2;
        font-weight: 300;
        font-size: 0.75rem;
        position: absolute;
        left: 50%;
        top: 1.25rem;
        transform: translateX(-50%);
        padding-top: 0.3rem;
        display: none;
    }

    a {
        height: 4rem;
        width: 100%;
        transition: 0.2s background ease;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        color: var(--color-brown);
        text-decoration: none;

        &:active,
        &:hover {
            background: rgba(var(--color-dark-rgb), 0.025);
        }

        &.active {
            [class*='icon\:'] {
                background: var(--color-dark);
            }

            color: var(--color-dark);
        }
    }

    [class*='icon\:'] {
        display: block;
        width: 100%;
        height: 1.125rem;
        margin: 0.625rem 0 0.5rem;
        background: #8a8553;
        transition: 0.2s background ease;

        &:hover {
            background: darken(#8a8553, 10%);
        }
    }

    .icon\:index {
        @include mask('../assets/icon-index.svg', 0.875rem 0.75rem);
    }

    .icon\:songs {
        @include mask('../assets/icon-songs.svg', 1rem 1.125rem);
    }

    .icon\:search {
        @include mask('../assets/icon-search.svg', 1.125rem 1.125rem);
    }

    .icon\:menu {
        @include mask('../assets/icon-more.svg', 1.125rem 0.25rem);
    }

    @media (max-height: 480px) {
        display: none;
    }

    @media (min-width: 992px) {
        width: 7.3rem !important;
        min-height: 100vh;
        flex-direction: column;
        justify-content: center;
        background-color: #f6f5e5;
        .logo {
            display: flex;
            &:hover {
                background: var(--color-dark);
            }
        }
        a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 0.875rem;
            height: 5.5rem;
            &:active,
            &:hover:not(.logo) {
                background: rgba(black, 0.025);
            }
        }
        [class*='icon\:'] {
            height: 1.5rem;
        }
        .icon\:index {
            @include mask('../assets/icon-index.svg', 1.1875rem 1rem);
        }

        .icon\:songs {
            @include mask('../assets/icon-songs.svg', 1.375rem 1.5rem);
        }

        .icon\:search {
            @include mask('../assets/icon-search.svg', 1.5rem 1.5rem);
        }

        .icon\:menu {
            @include mask('../assets/icon-more.svg', 1.5rem 0.3rem);
        }
    }

    @media (min-width: 992px) {
        //display: none;
    }
}
</style>
