import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "links font:sans-serif" }

import { PropType } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Links',
  props: {
    links: Object as PropType<
        {
            url: string;
            title: string;
        }[]
    >,
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.links, (link, index) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        key: `link${index}`,
        to: link.url
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(link.title), 1)
        ]),
        _: 2
      }, 1032, ["to"]))
    }), 128))
  ]))
}
}

})