<script lang="ts" setup>
import { Ref, ref, onMounted, onActivated, onDeactivated } from 'vue';
import SongListItem from '@/components/SongListItem.vue';
import { session } from '@/store';

const fontSize: number = session.fontSize;

const topHeight: Ref<number> = ref(fontSize * 23.125); // 370px

const calculateHeights = ref(() => {
    // Placeholder
});

// Scrolling
const loading: Ref<boolean> = ref(true);
const teleportLoading: Ref<boolean> = ref(true);

const showHeader: Ref<boolean> = ref(false);

onMounted(async () => {
    calculateHeights.value();

    loading.value = false;
    teleportLoading.value = false;
    setTimeout(() => {
        setSectionTitle();
    }, 1000);

    window.addEventListener('scroll', setSectionTitle);
});

onActivated(() => {
    teleportLoading.value = false;
});

onDeactivated(() => {
    teleportLoading.value = true;
});

let titles: any = [];
const activeSection = ref();

function setSectionTitle(): void {
    const song_elm = document.querySelectorAll('.song-item');

    let scroll_length = window.scrollY;

    song_elm.forEach((elm) => {
        let title = elm.getAttribute('section');
        if (isInViewport(elm) && !titles.includes(title)) {
            activeSection.value = title;
        } else {
            titles.splice(titles.indexOf(title));
        }
    });

    if (scroll_length > 330) {
        showHeader.value = true;
    } else {
        showHeader.value = false;
    }
}

function isInViewport(element: any) {
    const rect = element.getBoundingClientRect();
    return rect.top > -300 && rect.top < 40 && rect.left >= 0;
}
</script>

<template>
    <main class="songs">
        <template v-if="!teleportLoading">
            <teleport to="#top-content">
                <div
                    class="top-section"
                    :class="{ visible: showHeader }"
                >
                    {{ activeSection }}
                </div>
            </teleport>
        </template>

        <div class="songs-list">
            <section
                class="list-header font:serif"
                :style="{ maxHeight: `${topHeight}px` }"
            >
                <div class="splash--title">
                    <h1>Nýggja sangbók</h1>

                    <p>Guds fólks</p>
                </div>
                <div
                    class="active-section font:serif weight:700"
                    style="display: none"
                >
                    {{ activeSection }}
                </div>
            </section>
            <div
                v-for="(song, index) in session.songs?.mobile"
                :key="`song-${index}`"
                class="max-width"
            >
                <h2
                    class="song-header font:serif"
                    v-if="song.type === 'header'"
                >
                    {{ song.section }}
                </h2>
                <song-list-item
                    class="song-item font:sans-serif"
                    :section="song.section"
                    :song="song"
                    v-if="song.type === 'song'"
                />
            </div>
        </div>
    </main>
</template>

<style lang="scss" scoped>
main {
    padding-top: env(safe-area-inset-top);
    position: relative;
    max-width: 100%;
}

.songs-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 4rem;
}

.list-header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    width: 100%;
    margin-bottom: 5rem;

    .splash--title {
        max-width: 12.5rem;
        margin: 4.25rem auto 0;
        text-align: center;
        text-transform: uppercase;
        color: var(--color-dark);
        transition: 1s all;

        h1 {
            margin: 1rem 0;
            padding-bottom: 0.5rem;
            text-align: center;
            font-size: 2.5rem;
            line-height: 1.2;
            font-weight: 400;
            border-bottom: 1px solid var(--color-dark);
        }

        p {
            margin: 1rem 0 0;
        }
    }
}

.song-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    min-height: 3rem;
    width: 100%;
    max-width: 100vw;
    color: var(--color-dark);
    font-size: 1rem;
    line-height: 1;
    border-top: 1px solid #f8f5dc;
    text-decoration: none;

    .song-link--name {
        text-overflow: ellipsis;
        overflow: hidden;
        height: 1.1rem;
        white-space: nowrap;
    }

    .song-link--number {
        margin-left: 0.5rem;
    }
}

.song-header {
    padding: 4rem 0 2rem;
    margin: 0;
    width: 100%;
    text-align: center;
    font-weight: 700;
}
</style>
