import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "links author-links font:sans-serif" }

import Author from '@/types/author';



export default /*@__PURE__*/_defineComponent({
  __name: 'AuthorLinks',
  props: {
    authors: Object as any,
},
  setup(__props) {

const props = __props;

function handleText(author: Author) {
    let text = author.key;

    author.songs.forEach((song, index) => {
        if (index === 0) {
            text += ` ${song}`;
            return;
        }

        text += `, ${song}`;
    });

    return text;
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.authors, (author, index) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        key: `author-${index}`,
        to: `/menu/index/people/${author?.item?.id || author.id}`
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(handleText(author?.item || author)), 1)
        ]),
        _: 2
      }, 1032, ["to"]))
    }), 128))
  ]))
}
}

})