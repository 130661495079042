import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'VFooter',
  setup(__props) {

const route = useRoute();
const path = computed(() => route.path);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", null, [
    _createVNode(_component_router_link, {
      to: "/list",
      class: "logo"
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("NÝGGJA"),
        _createElementVNode("br", null, null, -1),
        _createTextVNode("SANGBÓK")
      ])),
      _: 1
    }),
    _createVNode(_component_router_link, {
      to: "/list",
      class: _normalizeClass(["font:sans-serif text:xs", { active: path.value.startsWith('/list') }])
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("div", { class: "icon:index" }, null, -1),
        _createTextVNode(" Yvirlit ")
      ])),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_router_link, {
      to: "/songs",
      class: _normalizeClass(["font:sans-serif text:xs", { active: path.value.startsWith('/song') }])
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [
        _createElementVNode("div", { class: "icon:songs" }, null, -1),
        _createTextVNode(" Sangir ")
      ])),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_router_link, {
      to: "/search",
      class: _normalizeClass(["font:sans-serif text:xs", { active: path.value.startsWith('/search') }])
    }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [
        _createElementVNode("div", { class: "icon:search" }, null, -1),
        _createTextVNode(" Leita ")
      ])),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_router_link, {
      to: "/menu",
      class: _normalizeClass(["font:sans-serif text:xs", { active: path.value.startsWith('/menu') }])
    }, {
      default: _withCtx(() => _cache[4] || (_cache[4] = [
        _createElementVNode("div", { class: "icon:menu" }, null, -1),
        _createTextVNode(" Meira ")
      ])),
      _: 1
    }, 8, ["class"])
  ]))
}
}

})