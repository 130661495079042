import axios from 'axios';

export default class PageService {
    public static async get(slug: string) {
        try {
            const customConfig = {
                auth: {
                    username: process.env.VUE_APP_API_USERNAME,
                    password: process.env.VUE_APP_API_PASSWORD,
                },
            };

            return await axios
                .get(`${process.env.VUE_APP_BASE_API}/wp-json/v1/pages/${slug}`, customConfig)
                .then(async (response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.error(error.response);
                });
        } catch {
            console.log('catch');
        }
    }
}
