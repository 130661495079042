import { createApp } from 'vue';
import App from './App.vue';
import '@capacitor/core';
import './scss/style.scss';
import router from './router';
import VirtualScroller from 'vue-virtual-scroller';
import { session } from './store';
import { ScreenOrientation } from '@capacitor/screen-orientation';

const app = createApp(App);

app.use(router).use(VirtualScroller);

let fontSizeHasBeenIncreased = false;

async function increaseFontSizeInLandscapeMode() {
    const html = document.querySelector('html');

    if (!html) {
        return;
    }

    try {
        // Get the current orientation asynchronously
        const orientationInfo = await ScreenOrientation.orientation();
        const orientation = orientationInfo.type; // Adjust this line based on how the orientation is provided

        if (orientation === 'landscape-primary' || orientation === 'landscape-secondary') {
            fontSizeHasBeenIncreased = true;
            html.style.fontSize = `${session.fontSize * 1.25}px`;
        } else if ((orientation === 'portrait-primary' || orientation === 'portrait-secondary') && fontSizeHasBeenIncreased) {
            html.style.fontSize = `${session.fontSize}px`;
        }
    } catch (error) {
        console.error('Error getting screen orientation:', error);
    }
}

increaseFontSizeInLandscapeMode();

// Adjust how you add the event listener based on the Capacitor plugin's documentation
ScreenOrientation.addListener('screenOrientationChange', () => {
    increaseFontSizeInLandscapeMode();
});

app.mount('#app');
