import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "index-sections" }
const _hoisted_2 = { class: "px:30 max-width" }

import { Ref, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import Song from '@/components/Song.vue';

import SongType from '@/types/song';
import Songs from '@/types/songs';

import { session } from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'Chords',
  setup(__props) {

const route = useRoute();
const song: Ref<SongType | null> = ref(null);
const songs: Songs | null = session.songs;

onMounted(() => {
    if (route.params.id && songs) {
        song.value = songs.general[Number(route.params.id) - 1];
    }
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: { name: 'Songs' },
        class: "back-icon"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("div", null, null, -1)
        ])),
        _: 1
      }),
      _cache[1] || (_cache[1] = _createElementVNode("h1", null, "Akkordir", -1))
    ]),
    _createVNode(Song, {
      song: song.value || undefined,
      chords: true
    }, null, 8, ["song"])
  ]))
}
}

})