import { Store } from '@/store';
const store = Store.getInstance();

export default class FavoriteService {
    public static async add(songNumber: number) {
        store.favorites.value = [...store.favorites.value, songNumber];
    }

    public static async remove(songNumber: number) {
        store.favorites.value = store.favorites.value.filter((value) => value !== songNumber);
    }

    public static isFavorite(songNumber: number): boolean {
        return store.favorites.value.includes(songNumber);
    }
}
