import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "song-header font:serif"
}

import { computed, onMounted, onActivated, onDeactivated, Ref, ref } from 'vue';

import { session } from '@/store';

import Songs from '@/types/songs';

import Context from '@/components/Context.vue';
import SongView from '@/components/Song.vue';
import TabletSongs from '@/components/TabletSongs.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Songs',
  setup(__props) {

const songs: Ref<Songs | null> = ref(session.songs);
const loading: Ref<boolean> = ref(true);
const teleportLoading: Ref<boolean> = ref(true);
const scroller: any = ref(null);
const hasSongParameter: Ref<boolean> = ref(false);
const isInLandscapeMode = ref(false);
const innerWidth = ref(window.innerWidth);

const componentKey: Ref<number> = ref(0); // for refreshing the scroller on breakpoint

const contextMenuSong: Ref<any> = ref(null);

function goToSong(number: any) {
    if (!number || number == '') {
        return;
    }

    setTimeout(() => {
        // find song index by song number
        let index: number | undefined = 0;

        if (isMobile.value) {
            // if mobile
            index = songs.value?.mobile.findIndex((song) => {
                return song.number == number;
            });
        } else {
            // if tablet
            if (number % 2 !== 0) {
                number = parseInt(number) + 1;
            }
            index = songs.value?.tablet.findIndex((song) => {
                return song.number == number;
            });
        }
        scroller.value.scrollToItem(index);
        setTimeout(() => {
            loading.value = false;
            teleportLoading.value = false;
            return scroller.value.scrollToItem(index);
        }, 100);
    }, 500);
}

onMounted(() => {
    isInLandscapeMode.value = window.matchMedia('(orientation: landscape)').matches;

    setFontSize();

    window.matchMedia('(orientation: landscape)').addEventListener('change', (e) => {
        isInLandscapeMode.value = e.matches;

        setFontSize();
    });
});

// Set font size if large and in landscape mode
function setFontSize() {
    // If landscape mode and text is large, make text even larger
    if (isInLandscapeMode.value && session.fontSize == 18) {
        session.fontSize = 20;
    }

    // If portrait mode and text is even larger, reset back to large
    if (!isInLandscapeMode.value && session.fontSize == 20) {
        session.fontSize = 18;
    }
}

onActivated(() => {
    addEventListener('resize', () => {
        innerWidth.value = window.innerWidth;
    });

    teleportLoading.value = true;
    loading.value = true;

    // go to song
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('song')) {
        hasSongParameter.value = true;
        goToSong(urlParams.get('song'));
    } else {
        loading.value = false;
        teleportLoading.value = false;
    }
});

const isMobile = computed(() => {
    if (isInLandscapeMode.value) {
        return innerWidth.value < 992;
    } else {
        return innerWidth.value < 768;
    }
});

const songsToRender = computed(() => {
    if (!songs.value) {
        return [];
    }

    return isMobile.value ? songs.value.mobile : songs.value.tablet;
});

onDeactivated(() => {
    teleportLoading.value = true;
    loading.value = true;
});

function toggleContextMenu(song_number: any) {
    if (!song_number) {
        contextMenuSong.value = null;
        return;
    }

    const song = songs.value?.general.find((x) => x.number === song_number);

    contextMenuSong.value = song;
}

return (_ctx: any,_cache: any) => {
  const _component_DynamicScrollerItem = _resolveComponent("DynamicScrollerItem")!
  const _component_DynamicScroller = _resolveComponent("DynamicScroller")!

  return (_openBlock(), _createElementBlock("main", {
    class: _normalizeClass(["songs no-padding", { mobile: isMobile.value }])
  }, [
    (songsToRender.value)
      ? (_openBlock(), _createBlock(_component_DynamicScroller, {
          key: componentKey.value,
          items: songsToRender.value,
          "min-item-size": 350,
          "key-field": "number",
          ref_key: "scroller",
          ref: scroller,
          class: _normalizeClass(["scroller", { 'is-visible': !loading.value, 'has-song-parameter': hasSongParameter.value }])
        }, {
          default: _withCtx(({ item, index, active }) => [
            _createVNode(_component_DynamicScrollerItem, {
              item: item,
              active: active,
              "size-dependencies": [item],
              "data-index": index,
              "key-field": item.number
            }, {
              default: _withCtx(() => [
                (item.type === 'header')
                  ? (_openBlock(), _createElementBlock("h2", _hoisted_1, _toDisplayString(item.section), 1))
                  : _createCommentVNode("", true),
                (isMobile.value)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (item.number !== 0)
                        ? (_openBlock(), _createBlock(SongView, {
                            key: 0,
                            song: item,
                            onContext: toggleContextMenu
                          }, null, 8, ["song"]))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true),
                (!isMobile.value)
                  ? (_openBlock(), _createBlock(TabletSongs, {
                      key: 2,
                      songs: item.songs,
                      onContext: toggleContextMenu
                    }, null, 8, ["songs"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["item", "active", "size-dependencies", "data-index", "key-field"])
          ]),
          _: 1
        }, 8, ["items", "class"]))
      : _createCommentVNode("", true),
    _createVNode(Context, {
      song: contextMenuSong.value,
      onHideContext: toggleContextMenu
    }, null, 8, ["song"])
  ], 2))
}
}

})