import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "search" }
const _hoisted_2 = { class: "search-input" }
const _hoisted_3 = {
  key: 0,
  class: "icon:search"
}
const _hoisted_4 = { class: "links song-links font:sans-serif max-width" }
const _hoisted_5 = { class: "song-link--name" }
const _hoisted_6 = { class: "song-link--number" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "number" }
const _hoisted_9 = {
  key: 0,
  class: "search-paragraph px:45 max-width text:center text:sm font:sans-serif"
}
const _hoisted_10 = {
  key: 1,
  class: "my:40 px:45 text:center text:sm max-width font:sans-serif"
}

import { onMounted, Ref, ref, watch } from 'vue';

import { useRoute } from 'vue-router';

import Fuse from 'fuse.js';

import { session } from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'Search',
  setup(__props) {

const route = useRoute();

const fuse = new Fuse([], {
    threshold: 0.2,
    distance: Infinity,
    includeScore: true,
    shouldSort: true,
    keys: [
        {
            name: 'titles',
            weight: 5,
        },
        'number',
        'authors.name',
        'authors.firstName',
        'authors.lastName',
        'text',
    ],
});

const isSearching: Ref<boolean> = ref(false);
const hits: Ref<any[]> = ref([]);
const query: Ref<string> = ref('');
const loading: Ref<boolean> = ref(false);

// Set Fuse collection

onMounted(() => {
    fuse.setCollection(session.searchable);
});

/**
 * Whenever 'query' changes, (fuzzy) search for songs
 */
let timeout = 0;
watch(query, (query) => {
    // debounce
    clearTimeout(timeout);

    const regex = /[-,.]/g;

    query = query.replaceAll(regex, '');

    loading.value = true;

    timeout = setTimeout(() => {
        hits.value = [];

        const results = fuse.search(query);

        hits.value = results.map((hit) => hit.item).slice(0, 50); // cap to 50 items

        loading.value = false;
    }, 250);
});

watch(route, () => {
    setTimeout(() => {
        isSearching.value = false;
        query.value = '';
        hits.value = [];
    }, 1000);
});

function shouldBlur() {
    if (query.value.length !== 0) {
        return;
    }

    isSearching.value = false;
}

function clearQuery() {
    query.value = '';
    isSearching.value = false;
}

function getTitles(songIndex: number): any[] {
    const song = session.songs?.general[songIndex];

    if (!song) {
        return [];
    }

    return song.titles;
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("section", {
      class: _normalizeClass(["max-width px:30", { 'border-bottom': query.value.length >= 3 && hits.value?.length }])
    }, [
      _createElementVNode("h1", {
        class: _normalizeClass(["transition", { hidden: isSearching.value }])
      }, " Leita ", 2),
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          onFocus: _cache[0] || (_cache[0] = ($event: any) => (isSearching.value = true)),
          onBlur: shouldBlur,
          placeholder: "Leita...",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((query).value = $event))
        }, null, 544), [
          [_vModelText, query.value]
        ]),
        (query.value.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3))
          : _createCommentVNode("", true),
        (query.value.length > 0)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              onClick: clearQuery,
              class: "icon:close"
            }))
          : _createCommentVNode("", true)
      ])
    ], 2),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(hits.value, (hit, index) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          key: `song-${index}`,
          to: `/songs?song=${hit?.number}`
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, _toDisplayString(getTitles(hit.index)?.[0]?.title), 1),
            _createElementVNode("div", _hoisted_6, [
              (hit.isChoral)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, "(k)"))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_8, _toDisplayString(hit?.number), 1)
            ])
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ]),
    (query.value.length < 3 && !hits.value?.length)
      ? (_openBlock(), _createElementBlock("p", _hoisted_9, " Nýt leitifunkuna at leita eftir sangum, niðurløgum ella kórum. Eisini kann leitast eftir yrkjara, týðara og meira. "))
      : _createCommentVNode("", true),
    (query.value.length > 2 && !hits.value?.length && !loading.value)
      ? (_openBlock(), _createElementBlock("p", _hoisted_10, " Onki varð funnið "))
      : _createCommentVNode("", true)
  ]))
}
}

})