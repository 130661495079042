<script lang="ts" setup>
import { computed, onMounted, onActivated, onDeactivated, Ref, ref } from 'vue';

import { session } from '@/store';

import Songs from '@/types/songs';

import Context from '@/components/Context.vue';
import SongView from '@/components/Song.vue';
import TabletSongs from '@/components/TabletSongs.vue';

const songs: Ref<Songs | null> = ref(session.songs);
const loading: Ref<boolean> = ref(true);
const teleportLoading: Ref<boolean> = ref(true);
const scroller: any = ref(null);
const hasSongParameter: Ref<boolean> = ref(false);
const isInLandscapeMode = ref(false);
const innerWidth = ref(window.innerWidth);

const componentKey: Ref<number> = ref(0); // for refreshing the scroller on breakpoint

const contextMenuSong: Ref<any> = ref(null);

function goToSong(number: any) {
    if (!number || number == '') {
        return;
    }

    setTimeout(() => {
        // find song index by song number
        let index: number | undefined = 0;

        if (isMobile.value) {
            // if mobile
            index = songs.value?.mobile.findIndex((song) => {
                return song.number == number;
            });
        } else {
            // if tablet
            if (number % 2 !== 0) {
                number = parseInt(number) + 1;
            }
            index = songs.value?.tablet.findIndex((song) => {
                return song.number == number;
            });
        }
        scroller.value.scrollToItem(index);
        setTimeout(() => {
            loading.value = false;
            teleportLoading.value = false;
            return scroller.value.scrollToItem(index);
        }, 100);
    }, 500);
}

onMounted(() => {
    isInLandscapeMode.value = window.matchMedia('(orientation: landscape)').matches;

    setFontSize();

    window.matchMedia('(orientation: landscape)').addEventListener('change', (e) => {
        isInLandscapeMode.value = e.matches;

        setFontSize();
    });
});

// Set font size if large and in landscape mode
function setFontSize() {
    // If landscape mode and text is large, make text even larger
    if (isInLandscapeMode.value && session.fontSize == 18) {
        session.fontSize = 20;
    }

    // If portrait mode and text is even larger, reset back to large
    if (!isInLandscapeMode.value && session.fontSize == 20) {
        session.fontSize = 18;
    }
}

onActivated(() => {
    addEventListener('resize', () => {
        innerWidth.value = window.innerWidth;
    });

    teleportLoading.value = true;
    loading.value = true;

    // go to song
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('song')) {
        hasSongParameter.value = true;
        goToSong(urlParams.get('song'));
    } else {
        loading.value = false;
        teleportLoading.value = false;
    }
});

const isMobile = computed(() => {
    if (isInLandscapeMode.value) {
        return innerWidth.value < 992;
    } else {
        return innerWidth.value < 768;
    }
});

const songsToRender = computed(() => {
    if (!songs.value) {
        return [];
    }

    return isMobile.value ? songs.value.mobile : songs.value.tablet;
});

onDeactivated(() => {
    teleportLoading.value = true;
    loading.value = true;
});

function toggleContextMenu(song_number: any) {
    if (!song_number) {
        contextMenuSong.value = null;
        return;
    }

    const song = songs.value?.general.find((x) => x.number === song_number);

    contextMenuSong.value = song;
}
</script>

<template>
    <main
        class="songs no-padding"
        :class="{ mobile: isMobile }"
    >
        <template v-if="songsToRender">
            <DynamicScroller
                :key="componentKey"
                :items="songsToRender"
                :min-item-size="350"
                key-field="number"
                ref="scroller"
                class="scroller"
                :class="{ 'is-visible': !loading, 'has-song-parameter': hasSongParameter }"
            >
                <template v-slot="{ item, index, active }">
                    <DynamicScrollerItem
                        :item="item"
                        :active="active"
                        :size-dependencies="[item]"
                        :data-index="index"
                        :key-field="item.number"
                    >
                        <h2
                            class="song-header font:serif"
                            v-if="item.type === 'header'"
                        >
                            {{ item.section }}
                        </h2>

                        <template v-if="isMobile">
                            <SongView
                                :song="item"
                                v-if="item.number !== 0"
                                @context="toggleContextMenu"
                            />
                        </template>

                        <template v-if="!isMobile">
                            <TabletSongs
                                :songs="item.songs"
                                @context="toggleContextMenu"
                            />
                        </template>
                    </DynamicScrollerItem>
                </template>
            </DynamicScroller>
        </template>

        <Context
            :song="contextMenuSong"
            @hideContext="toggleContextMenu"
        />
    </main>
</template>

<style lang="scss" scoped>
@import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

body {
    overflow: hidden;
}
main {
    overflow: hidden;
}
.song-header {
    text-align: center;
    padding: 10.375rem 2rem 9.375rem;
    font-size: 1.5rem;
    font-weight: 700;
    width: 100%;
}

.song-header-dummy {
    width: 0;
}

.vue-recycle-scroller {
    height: 100vh;
    overflow-y: auto;
    opacity: 0;
    transition: ease opacity 0.2s, ease transform 0.3s;
    &.has-song-parameter {
        transform: translateY(2rem);
    }
    &.is-visible {
        transform: translateY(0);
        opacity: 1;
    }
}
</style>
