<script lang="ts" setup>
import { Ref, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import SongListItem from '@/components/SongListItem.vue';

import Songs from '@/types/songs';
import Section from '@/types/section';

import { session } from '@/store';

const route = useRoute();
const songs: Songs | null = session.songs;
const sections: Section[] | null = session.sections;

const section: Ref<Section | null> = ref(null);

const loading: Ref<boolean> = ref(true);

onMounted(() => {
    if (route.params.id) {
        const findSection = sections?.find((findSection: Section) => findSection.id === Number(route.params.id));

        if (findSection) {
            section.value = findSection;
        }
    }

    setTimeout(() => {
        loading.value = false;
    }, 200);
});
</script>

<template>
    <main v-if="section">
        <section class="title-with-back px:30 border-bottom max-width">
            <router-link
                :to="{ name: 'IndexSections' }"
                class="back-icon"
            >
                <div></div>
            </router-link>
            <h1>{{ section.title }}</h1>
        </section>

        <div class="links song-links max-width font:sans-serif">
            <song-list-item
                v-for="(song, index) in songs?.general.slice(section.from - 1, section.to - 1)"
                :key="`song-${index}`"
                :song="song"
            />
        </div>
    </main>
</template>
