<script lang="ts" setup></script>

<template>
    <div class="loader"></div>
</template>

<style lang="scss" scoped>
@import '@/scss/mixins';

.loader {
    width: 1.5rem;
    height: 1.5rem;
    @include mask('../assets/icon-loading.svg', 1.5rem 1.5rem);

    background: var(--color-brown);

    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>
