import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "error-page" }

import { onMounted } from 'vue';
import { session } from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'Error',
  setup(__props) {

onMounted(() => {
    session.activeSplash = false;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("h1", null, "Ein feilur hendi", -1),
    _createElementVNode("p", null, "Vinarliga lat appina aftur og byrja av nýggjum.", -1)
  ])))
}
}

})