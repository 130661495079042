<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'IndexSong',

    props: ['song'],
});
</script>

<template>
    <router-link :to="`/songs?song=${song.number}`">
        <div
            class="song-link--name"
            :class="song.type"
        >
            {{ song.title }}
        </div>
        <div class="song-link--number">
            <span v-if="song.is_choral">(k)</span> <span class="number">{{ song.number }}</span>
        </div>
    </router-link>
</template>
