import { Share } from '@capacitor/share';

import Song from '@/types/song';

const davidMedia = [
    234, 729, 567, 6, 390, 758, 285, 251, 112, 458, 53, 1272, 773, 126, 46, 171, 299, 558, 1099, 927, 817, 769, 779, 183, 518, 316, 1034, 309, 185,
    761, 714, 1283, 713, 918, 941, 34, 840, 107, 1249, 1105, 1399,
];

export default class ShareService {
    public static async shareVerse(verse: any, type: string) {
        await Share.share({
            title: `Eitt ${type} frá Nýggju Sangbók Guds Fólk`,
            text: verse.title,
            dialogTitle: `Deil ${type}`,
        });
    }

    public static async shareSong(song: Song) {
        const verses: string[] = [];

        for (const verse of song.verses) {
            verses.push(verse.text);
        }

        const title = song.titles[0]?.title;

        let copyright = `© Nýggja Sangbók Guds Fólk Appin`;

        if (davidMedia.includes(song.number)) {
            copyright = `Úr Nýggju Sangbók Guds Fólk Appini\n\n© David Media AB`;
        }

        const text = `${verses.join('\n\n')}\n\nSangur nummar ${song.number}\n\n${copyright}`;

        await Share.share({
            title: title,
            text: text,
            dialogTitle: `Deil sang`,
        });
    }
}
