<script lang="ts" setup>
import { ref, Ref, PropType, defineProps, defineEmits } from 'vue';

import { Store } from '@/store';
import FavoriteService from '@/services/Favorite';
import ShareService from '@/services/Share';
import Song from '@/types/song';
const store = Store.getInstance();

const showAuthorMenu: Ref<boolean> = ref(false);

const props = defineProps({
    song: Object as PropType<Song>,
});

const emit = defineEmits(['hideContext']);

function favoriteLabel() {
    if (isFavorite(Number(props.song?.number))) {
        return 'Avynda';
    }
    return 'Ynda';
}

function isFavorite(number: any) {
    if (!number) {
        return false;
    }

    return store.favorites.value.includes(Number(number));
}

function hideContextMenu() {
    emit('hideContext', null);

    showAuthorMenu.value = false;
}

function favourite() {
    hideContextMenu();
    if (FavoriteService.isFavorite(Number(props.song?.number))) {
        return FavoriteService.remove(Number(props.song?.number));
    }

    FavoriteService.add(Number(props.song?.number));
}

async function share() {
    if (props.song) {
        ShareService.shareSong(props.song);
    }
}

function clickAway(e: any) {
    const target = e.target;

    if (target.classList.contains?.('context-menu--levels')) {
        hideContextMenu();
    }
}
</script>

<template>
    <div
        class="context-menu"
        :class="{ active: props.song, 'level-2': showAuthorMenu }"
        @click="clickAway"
    >
        <div
            class="overlay"
            @click="hideContextMenu"
        ></div>

        <div class="context-menu--btns">
            <div class="context-menu--levels">
                <div class="context-menu--btns-main context-menu--level-1">
                    <div
                        class="context-menu--btn font:sans-serif"
                        @click="favourite"
                    >
                        {{ favoriteLabel() }}
                    </div>

                    <div
                        class="context-menu--btn font:sans-serif"
                        @click="share"
                    >
                        Deil sang
                    </div>

                    <router-link
                        v-show="song?.chords"
                        class="context-menu--btn font:sans-serif"
                        @click="hideContextMenu"
                        :to="`/song/${song?.number}`"
                    >
                        Vís akkordir
                    </router-link>

                    <div
                        class="context-menu--btn font:sans-serif chevron"
                        @click="showAuthorMenu = true"
                    >
                        Yrkjarar, týðarar og tónaskøld
                    </div>
                </div>

                <div class="context-menu--btns-main context-menu--level-2">
                    <div
                        class="context-menu--btn font:sans-serif chevron reverse"
                        @click="showAuthorMenu = false"
                    >
                        Aftur
                    </div>

                    <router-link
                        v-for="(author, index) in song?.authors"
                        :key="index"
                        class="context-menu--btn font:sans-serif"
                        @click="hideContextMenu"
                        :to="`/menu/index/people/${author?.id}?from=song-${song?.number}`"
                        >{{ author?.first_name || '' }} {{ author?.last_name || '' }}</router-link
                    >
                </div>
            </div>

            <div
                class="context-menu--btn context-menu--btn-close font:sans-serif"
                @click="hideContextMenu"
            >
                Lat aftur
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@/scss/mixins';

.context-menu {
    z-index: 9999;
    pointer-events: none;

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(black, 0.5);
        transition: 0.2s all ease;
        opacity: 0;
        z-index: 10000;
    }

    .context-menu--btns {
        display: flex;
        flex-direction: column;
        position: fixed;
        bottom: calc(1.25rem + env(safe-area-inset-bottom));
        //left: 1.25rem;
        //right: 1.25rem;
        font-size: 16px;

        left: 50%;
        transform: translate(-50%, 120%);
        padding: 0 1rem;
        z-index: 10010;
        width: 100%;
        //transform: translateY(calc(100% + 2.5rem));
        //transform: translateY(120%);
        transition: 0.2s all ease;

        .context-menu--levels {
            width: 100%;
            padding-bottom: 5.5rem;
            display: flex;
            height: 100vh;
            position: relative;

            .context-menu--btns-main {
                background-color: var(--color-background);
                display: flex;
                flex-direction: column;
                border-radius: 10px;
                margin-bottom: 1.25rem;
                position: absolute;
                width: 100%;
                bottom: 0;
                //margin-bottom: 4.5rem;
                overflow: hidden;

                transition: ease transform 0.2s;
                padding: 1rem 0;

                // &.context-menu--level-1 {
                // 	//transform: translateX(120%);
                // }

                &.context-menu--level-2 {
                    transform: translateX(120%);
                }
            }

            .context-menu--btn {
                cursor: pointer;
                color: var(--color-dark);
                font-size: 1em;
                line-height: 1;
                font-weight: 700;
                text-align: center;
                text-decoration: none;
                padding: 1.25em 2em;
                border-bottom: 1px solid #f8f5dc;
                transition: 0.2s all ease;

                // &:active,
                // &:hover {
                // 	//background: var(--color-background-600);
                // }

                // &:first-child {
                // 	//padding-top: 2rem;
                // }

                &:last-child {
                    //padding-bottom: 2rem;
                    border-bottom: none;
                }

                &.chevron {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &::after,
                    &::before {
                        content: '';

                        @include mask('../assets/icon-chevron.svg', 0.875rem 0.875rem);

                        display: inline-block;
                        width: 0.875rem;
                        height: 0.875rem;
                        margin: 0 0 0 0.5rem;
                        background: var(--color-brown);
                    }

                    &::before {
                        display: none;
                    }

                    &.reverse {
                        &::before {
                            display: inline-block;
                            transform: rotate(180deg);
                            margin: 0 0.5rem 0 0;
                        }

                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }

        .context-menu--btn-close {
            padding: 0 2rem !important;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 4.25rem;
            text-transform: uppercase;

            color: var(--color-brown);
            background-color: var(--color-background);

            border-radius: 10px;
            cursor: pointer;
        }

        @media (min-width: 768px) {
            max-width: 24rem;
            overflow: hidden;
            bottom: 1em;
            //left: 50%;
            //transform: translateX(-50%);
        }

        // @media (min-width: 768px) {
        // 	width: 21rem;
        // 	right: unset;
        // 	left: 50%;
        // 	transform: translate(-50%, calc(100% + 2.5rem));
        // }
    }

    &.active {
        pointer-events: all;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        .overlay {
            opacity: 1;
        }
        .context-menu--btns {
            transform: translate(-50%, 0);
        }
        // .context-menu--level-1 {
        // 	//transform: translateY(0) !important;
        // }

        @media (min-width: 768px) {
            .context-menu--btns {
                // //transform: translateY(50%);
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &.level-2 {
        .context-menu--level-1 {
            transform: translateX(-120%) !important;
        }
        .context-menu--level-2 {
            transform: translateX(0) !important;
        }
    }
}
</style>
