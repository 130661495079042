import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { onMounted, ref, Ref, watch } from 'vue';

import { Preferences } from '@capacitor/preferences';

import { session } from '@/store';

import SongsService from '@/services/Songs';
import AuthorService from '@/services/Authors';
import SectionsService from '@/services/Sections';

import VSplash from '@/components/VSplash.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

onMounted(async () => {
    Preferences.get({ key: 'fontSize' }).then((res) => {
        const storedFontSize = Number(res.value || 16);

        session.fontSize = storedFontSize;
    });

    // Get base data
    const songsReady: Ref<boolean> = ref(false);
    const authorsReady: Ref<boolean> = ref(false);
    const sectionsReady: Ref<boolean> = ref(false);

    // Set songs
    if (!session.songs) {
        await SongsService.getSongs().then(() => {
            songsReady.value = true;
        });
    }
    // Set authors
    if (!session.authors) {
        await AuthorService.getAuthors().then(() => {
            authorsReady.value = true;
        });
    }
    // Set sections
    if (!session.sections) {
        await SectionsService.getSections().then(() => {
            sectionsReady.value = true;
        });
    }

    // hide splash screen if everything has loaded
    if (session.songs && session.authors && session.sections) {
        session.activeSplash = false;
    }

    // Populate the searchable array
    if (session.songs?.general) {
        const regex = /[-,.0-9]/g;

        session.searchable = [];

        let i = 0;

        for (const song of session.songs.general) {
            // Get first verse text, split by lines.
            const text = song.verses.reduce((previous: string[], current) => {
                return [
                    ...previous,
                    ...current.text
                        .replaceAll(regex, '')
                        .split('\n')
                        .map((line) => line.trim()),
                ];
            }, []);

            session.searchable.push({
                index: i++,
                number: song.number,
                type: song.type,
                titles: song.titles
                    .reduce((previous: string[], current) => [...previous, current.title], [])
                    .map((title) => title.replaceAll(regex, '')),
                text,
                isChoral: song.titles.some((title) => title.is_choral),
                authors: song.authors.map((author) => ({
                    name: author.first_name + ' ' + author.last_name,
                    firstName: author.first_name,
                    lastName: author.last_name,
                })),
            } as never);
        }
    }

    console.log(session.searchable);

    let resizeTimer: any;
    window.addEventListener('resize', () => {
        document.body.classList.add('resize-animation-stopper');

        clearTimeout(resizeTimer);

        resizeTimer = setTimeout(() => {
            document.body.classList.remove('resize-animation-stopper');
        }, 400);
    });
});

watch(
    () => session.fontSize,
    (fontSize) => {
        const html = document.querySelector('html');

        if (!html) {
            return;
        }

        html.style.fontSize = `${fontSize}px`;
    },
);

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(VSplash),
    (!_unref(session).activeSplash)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }, {
          default: _withCtx(({ Component }) => [
            _createVNode(_Transition, { name: 'slide-right' }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})