import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "about font:sans-serif" }
const _hoisted_2 = { class: "px:30 max-width" }
const _hoisted_3 = {
  key: 0,
  class: "page"
}
const _hoisted_4 = { class: "font:serif" }

import { onActivated, ref } from 'vue';

import PageService from '@/services/Pages';

import VHtml from '@/components/VHtml.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'About',
  setup(__props) {

const page = ref();

onActivated(async () => {
    const data = await PageService.get('um-appina');

    page.value = data;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      (page.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("h1", _hoisted_4, _toDisplayString(page.value.title), 1),
            _createVNode(VHtml, {
              content: page.value.content
            }, null, 8, ["content"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})