import axios from 'axios';

import type Songs from '@/types/songs';
import type Song from '@/types/song';

import { Network } from '@capacitor/network';
import { Preferences } from '@capacitor/preferences';

import { DB, session } from '@/store';
import router from '@/router';
const db = DB.getInstance();

export default class SongsService {
    public static async getSongs(): Promise<Songs | null> {
        // check if the device has a connection to the internet
        const networkStatus = await Network.getStatus();
        const isConnected = networkStatus.connected;

        // last sync time
        const lastSyncTime = await Preferences.get({
            key: 'songs_time',
        });

        // songs to return
        let songs: Songs | null = null;

        // NO LOCAL SONGS AND NO INTERNET CONNECTION
        if (!lastSyncTime.value && !isConnected) {
            router.push('/no-connection');
            return null;
        }

        // FIRST TIME SYNC
        if (!lastSyncTime.value && isConnected) {
            await SongsService.getSongsFromAPI(lastSyncTime.value).then(async (songsFromAPI) => {
                if (songsFromAPI) {
                    await db.saveSongs(songsFromAPI);
                    songs = await SongsService.renderSongs(songsFromAPI);
                }
            });
        }

        // :: CHECK IF API HAS UPDATES
        if (lastSyncTime.value) {
            await SongsService.getSongsFromAPI(lastSyncTime.value).then(async (songsFromAPI) => {
                if (songsFromAPI) {
                    await db.updateSongs(songsFromAPI).then(async () => {
                        console.log('Songs updated');
                    });

                    await db.getSongs().then(async (res: Song[]) => {
                        const songsToRender = res.sort((a, b) => a.number - b.number);
                        songs = await SongsService.renderSongs(songsToRender);
                    });
                }
            });
        }

        // GET LOCAL RENDERED SONGS
        if (lastSyncTime.value) {
            await SongsService.getLocalRenderedSongs().then(async (res) => {
                if (res) {
                    songs = res;
                }
            });
        }

        if (songs) {
            session.songs = songs;
        }

        return songs;
    }

    /**
     *
     * @param lastSyncTime
     * @returns
     */
    private static async getSongsFromAPI(lastSyncTime: string | null): Promise<Song[] | null> {
        let songsFromAPI: Song[] | null = null;

        // fetch the songs from the server
        try {
            const customConfig = {
                auth: {
                    username: process.env.VUE_APP_API_USERNAME,
                    password: process.env.VUE_APP_API_PASSWORD,
                },
            };

            await axios
                .get(`${process.env.VUE_APP_BASE_API}/wp-json/v1/songs?time=${lastSyncTime || 0}`, customConfig)
                .then(async (response) => {
                    if (!response.data?.length) {
                        return;
                    }

                    // set last sync time
                    Preferences.set({
                        key: 'songs_time',
                        value: JSON.stringify(Math.floor(new Date().getTime() / 1000)),
                    });

                    await SongsService.filterSongsByNumber(response.data).then((sortedSongs) => {
                        songsFromAPI = sortedSongs;
                    });

                    return songsFromAPI;
                })
                .catch((error) => {
                    console.error(error.response);
                });

            //return songs;
        } catch {
            console.log('catch');
        }

        return songsFromAPI;
    }

    /**
     *
     * @param songs
     * @returns
     */
    private static async filterSongsByNumber(songs: Song[]): Promise<Song[] | null> {
        const apiResponse: Song[] = songs;
        let sorted: Song[] | null = null;

        // if there are already songs in storage, we merge the array with the data from the server
        if (apiResponse.length) {
            // filter out the songs that are updated
            const filteredSongs = apiResponse.filter(function (entry) {
                return !apiResponse.some(function (elm: Song) {
                    return entry.number === elm.number;
                });
            });

            // merge filtered songs and the songs from the server
            sorted = [...filteredSongs, ...apiResponse];
        }

        // sort songs by number
        sorted = apiResponse.sort((a, b) => a.number - b.number);

        return sorted;
    }

    /**
     *
     * @returns
     */
    private static async getLocalRenderedSongs() {
        return db.getRenderedSongs().then((res: Songs | null) => {
            if (res && res.general.length > 0) {
                return res;
            }
        });
    }

    /**
     *
     * @param songs
     * @returns
     */
    private static async renderSongs(songs: Song[]) {
        const new_list: Songs = { general: [], mobile: [], tablet: [] };

        let index = 0;
        let current_section: any = '';

        let tables_song_pair: any = {
            number: Number,
            songs: [],
        };

        for (const song of songs) {
            if (song.section !== current_section) {
                const headerObject = {
                    _id: song._id,
                    section: song.section,
                    type: 'header',
                    number: 0,
                    chords: '',
                    verses: [],
                    meta: '',
                    titles: [],
                    authors: [],
                };

                new_list.mobile.push(headerObject);
                new_list.tablet.push(headerObject);

                current_section = song.section;
            }

            tables_song_pair.songs.push(song);
            tables_song_pair.number = song.number;
            index++;

            if (index % 2 == 0) {
                new_list.tablet.push(tables_song_pair);
                tables_song_pair = {
                    number: null,
                    songs: [],
                };
                index = 0;
            }

            new_list.general.push(song);
            new_list.mobile.push(song);
        }

        await db.saveRenderedSongs(new_list);

        return new_list;
    }
}
