/
<script lang="ts" setup>
import { ref, Ref, watch } from 'vue';

import { useRoute } from 'vue-router';

// Data

const route = useRoute();

const path: Ref<any> = ref({ name: route.meta?.link });

// Watchers

watch(route, (routeValue) => {
    if (route.query.from) {
        const query = route.query.from.toString();

        const [type, number] = query.split('-');

        if (type == 'song') {
            path.value = { name: 'Songs', query: { song: number } };
        }

        return;
    }

    if (routeValue.meta.link) {
        path.value = { name: routeValue.meta.link };

        return;
    }

    path.value = '';
});
</script>

<template>
    <header>
        <div class="top-header">
            <router-link
                v-if="path"
                :to="path"
                class="back-icon"
            >
                <div></div>
            </router-link>

            <div id="top-content"></div>
        </div>
        <div class="header-gradient"></div>
    </header>
</template>

<style lang="scss" scoped>
@import '@/scss/mixins';

header {
    display: flex;
    height: auto;
    width: 100%;
    position: fixed;
    z-index: 900;
    background: transparent;
    pointer-events: none;

    .top-header {
        width: 100%;
        min-height: calc(4.25rem + env(safe-area-inset-top));
        position: relative;
        padding-top: env(safe-area-inset-top);
        z-index: 20;

        .back-icon {
            position: absolute;
            left: 0.875rem;
            bottom: 0.875rem;
            z-index: 20;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            pointer-events: all;
        }

        #top-content {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            background: transparent;
        }
    }

    .header-gradient {
        display: flex;
        width: 100%;
        height: calc(env(safe-area-inset-top) * 2.5);
        background: linear-gradient(181.42deg, #fffdeb 40.93%, rgba(255, 253, 235, 0) 98.79%);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
    }

    @media (min-width: 992px) {
        width: calc(100% - 7.3rem);

        .top-header {
            width: 100%;
            height: 100%;
            position: relative;

            .back-icon {
                display: none;
            }
        }
    }
}
</style>
