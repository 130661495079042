import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "title-with-back px:30 border-bottom max-width" }
const _hoisted_3 = { class: "links song-links max-width font:sans-serif" }

import { Ref, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import SongListItem from '@/components/SongListItem.vue';

import Songs from '@/types/songs';
import Section from '@/types/section';

import { session } from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'IndexSectionsSongs',
  setup(__props) {

const route = useRoute();
const songs: Songs | null = session.songs;
const sections: Section[] | null = session.sections;

const section: Ref<Section | null> = ref(null);

const loading: Ref<boolean> = ref(true);

onMounted(() => {
    if (route.params.id) {
        const findSection = sections?.find((findSection: Section) => findSection.id === Number(route.params.id));

        if (findSection) {
            section.value = findSection;
        }
    }

    setTimeout(() => {
        loading.value = false;
    }, 200);
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (section.value)
    ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
        _createElementVNode("section", _hoisted_2, [
          _createVNode(_component_router_link, {
            to: { name: 'IndexSections' },
            class: "back-icon"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("div", null, null, -1)
            ])),
            _: 1
          }),
          _createElementVNode("h1", null, _toDisplayString(section.value.title), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(songs)?.general.slice(section.value.from - 1, section.value.to - 1), (song, index) => {
            return (_openBlock(), _createBlock(SongListItem, {
              key: `song-${index}`,
              song: song
            }, null, 8, ["song"]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})