<script lang="ts" setup>
import Links from '@/components/Links.vue';

const links = [
    { title: 'Yrkjarar, týðarar og tónaskøld', url: '/menu/index/people' },
    { title: 'Deildir', url: '/menu/index/sections' },
    { title: 'Yvirlit yvir sangir, niðurløg og kór', url: '/menu/index/songs' },
];
</script>

<template>
    <main class="index">
        <section class="title-with-back border-bottom px:30 max-width">
            <router-link
                :to="{ name: 'Menu' }"
                class="back-icon"
            >
                <div></div>
            </router-link>
            <h1>Innihaldsyvirlit</h1>
        </section>

        <Links
            class="max-width"
            :links="links"
        />
    </main>
</template>
