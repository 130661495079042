<script lang="ts" setup>
import Links from '@/components/Links.vue';

import { session, Store } from '@/store';
const store = Store.getInstance();

const version = process.env.VUE_APP_VERSION;

const links = [
    // { title: 'Innihaldsyvirlit', url: '/menu/index' },
    { title: 'Yrkjarar, týðarar og tónaskøld', url: '/menu/index/people' },
    { title: 'Deildir', url: '/menu/index/sections' },
    { title: 'Yvirlit yvir sangir, niðurløg og kór', url: '/menu/index/songs' },
    { title: 'Yndissangir', url: '/menu/favorite' },
    // { title: 'Um appina', url: '/menu/about' },
    { title: 'Rættindi', url: '/menu/rights' },
    { title: 'Privatlívspolitikkur', url: '/menu/privacy' },
];

const html = document.querySelector('html');

function setFontSize(newSize: number) {
    if (session.fontSize === newSize) {
        return;
    }

    session.fontSize = newSize;
    store.fontSize.value = newSize;

    if (html) {
        html.style.fontSize = `${session.fontSize}px`;
    }
}
</script>

<template>
    <main class="index">
        <section class="border-bottom max-width px:30">
            <h1>Valmynd</h1>
        </section>

        <Links
            class="max-width"
            :links="links"
        />

        <section class="fontSize-container max-width">
            <label
                for="fontSize"
                class="font:serif weight:700"
                >Skriftstødd</label
            >
            <div class="font-resizer">
                <div
                    class="calc font:sans-serif"
                    @click="setFontSize(16)"
                    :class="{ active: session.fontSize === 16 }"
                >
                    Vanlig
                </div>
                <div
                    class="calc font:sans-serif"
                    @click="setFontSize(18)"
                    :class="{ active: session.fontSize === 18 || session.fontSize == 20 }"
                >
                    Stór
                </div>
            </div>
        </section>

        <section class="app-info max-width">
            <span>Útgáva {{ version }}</span>
        </section>
    </main>
</template>

<style lang="scss" scoped>
@import '@/scss/mixins';
.fontSize-container {
    padding: 3.5rem 1.875rem 1.25rem;

    .font-resizer {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-top: 1.25rem;

        .calc {
            cursor: pointer;
            background-color: #f8f5dc;
            color: var(--color-dark);
            border-radius: 4px;
            width: 4.625rem;
            height: 2.5rem;
            display: flex;
            justify-content: center;
            align-items: center;

            &.active {
                cursor: default;
                color: #f8f5dc;
                background-color: var(--color-dark);
            }
        }
    }
}

.reloader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 253, 235, 0.7);

    display: flex;
    justify-content: center;
    align-items: center;
}

.app-info {
    padding: 2.5rem 1.875rem 1.25rem;
    width: 100%;
    color: var(--color-dark);
    font-family: var(--font-sans);
    font-size: 0.875rem;
    margin-bottom: 5rem;
}
</style>
