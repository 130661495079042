<script lang="ts" setup>
import { defineProps, PropType, computed } from 'vue';

import Title from '@/types/title';
import Song from '@/types/song';

const props = defineProps({
    song: Object as PropType<Song>,
});

const isChoral = computed(() => {
    let returnValue = false;
    props.song?.titles.forEach((title: Title) => {
        if (title.is_choral) {
            returnValue = true;
        }
    });

    return returnValue;
});
</script>

<template>
    <router-link
        v-if="props.song?.type === 'song'"
        :to="`/songs?song=${props.song?.number}`"
    >
        <div class="song-link--name">
            {{ props.song?.titles[0].title }}
        </div>

        <div class="song-link--number">
            <span v-if="isChoral">(k)</span> <span class="number">{{ props.song?.number }}</span>
        </div>
    </router-link>
</template>
