<script lang="ts" setup>
import Author from '@/types/author';
import { defineProps } from 'vue';

const props = defineProps({
    authors: Object as any,
});

function handleText(author: Author) {
    let text = author.key;

    author.songs.forEach((song, index) => {
        if (index === 0) {
            text += ` ${song}`;
            return;
        }

        text += `, ${song}`;
    });

    return text;
}
</script>

<template>
    <div class="links author-links font:sans-serif">
        <router-link
            v-for="(author, index) in props.authors"
            :key="`author-${index}`"
            :to="`/menu/index/people/${author?.item?.id || author.id}`"
        >
            {{ handleText(author?.item || author) }}
        </router-link>
    </div>
</template>
