import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

import { onMounted, ref, Ref } from 'vue';

// Props


export default /*@__PURE__*/_defineComponent({
  __name: 'VHtml',
  props: {
    content: {}
  },
  setup(__props: any) {



// Data

const container: Ref<HTMLDivElement | undefined> = ref();

// Lifecycle Hooks

onMounted(() => {
    addTableWrapper();
});

// Functions

function addTableWrapper() {
    if (!container.value) {
        return;
    }

    const tables = container.value.querySelectorAll('table');

    for (const table of tables) {
        if (!table.parentNode) {
            continue;
        }

        const wrapper = document.createElement('div');

        wrapper.className = 'html-table';

        table.parentNode.insertBefore(wrapper, table);

        wrapper.appendChild(table);
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "container",
    ref: container,
    innerHTML: _ctx.content,
    class: "html"
  }, null, 8, _hoisted_1))
}
}

})