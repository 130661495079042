import axios from 'axios';

import Section from '@/types/section';
import { Network } from '@capacitor/network';
import { Preferences } from '@capacitor/preferences';

import { DB, session } from '@/store';
import router from '@/router';
const db = DB.getInstance();

export default class SectionsService {
    public static async getSections(): Promise<Section[] | null> {
        // check if the device has a connection to the internet
        const status = await Network.getStatus();
        const isConnected = status.connected;

        // last sync time
        const lastSyncTime = await Preferences.get({
            key: 'sections_time',
        });

        let sections: Section[] | null = null;

        // :: NO LOCAL AUTHORS AND NO INTERNET CONNECTION
        if (!lastSyncTime.value && !isConnected) {
            router.push('/no-connection');
            return null;
        }

        // :: FIRST TIME SYNC
        if (!lastSyncTime.value && isConnected) {
            await SectionsService.getSectionsFromAPI(lastSyncTime.value).then(async (sectionsFromAPI) => {
                if (sectionsFromAPI) {
                    await db.saveSections(sectionsFromAPI);
                    sections = sectionsFromAPI;
                }
            });
        }

        // :: CHECK IF API HAS UPDATES
        if (lastSyncTime.value) {
            await SectionsService.getSectionsFromAPI(lastSyncTime.value).then(async (sectionsFromAPI) => {
                if (sectionsFromAPI) {
                    await db.updateSections(sectionsFromAPI).then(async () => {
                        console.log('Sections updated');
                    });

                    await db.getSections().then(async (res) => {
                        sections = res;
                        const sectionsFromAPI = sections.sort((a, b) => a.from - b.from);
                        sections = sectionsFromAPI;
                    });
                }
            });
        }

        // :: GET LOCAL SECTIONS
        if (lastSyncTime.value) {
            await SectionsService.getLocalSections().then((sectionsFromLocal: Section[] | null) => {
                if (!sectionsFromLocal) {
                    return null;
                }
                sections = sectionsFromLocal.sort((a, b) => a.from - b.from);
            });
        }

        if (sections) {
            session.sections = sections;
        }

        return sections;
    }

    /**
     *
     * @returns
     */
    private static async getLocalSections() {
        let sectionsFromLocal: Section[] | null = null;

        await db.getSections().then(async (res) => {
            if (res) {
                const sections = res;
                sectionsFromLocal = sections.sort((a, b) => a.from - b.from);
            }
        });

        return sectionsFromLocal;
    }

    /**
     *
     * @param lastSyncTime
     * @returns
     */
    private static async getSectionsFromAPI(lastSyncTime: string | null): Promise<Section[] | null> {
        let sectionsFromAPI: Section[] | null = null;

        try {
            const customConfig = {
                auth: {
                    username: process.env.VUE_APP_API_USERNAME,
                    password: process.env.VUE_APP_API_PASSWORD,
                },
            };

            await axios
                .get(`${process.env.VUE_APP_BASE_API}/wp-json/v1/sections?time=${lastSyncTime || 0}`, customConfig)
                .then(async (response) => {
                    if (!response.data?.length) {
                        return;
                    }

                    // set last sync time
                    await Preferences.set({
                        key: 'sections_time',
                        value: JSON.stringify(Date.now() / 1000),
                    });

                    await SectionsService.filterSectionsByFrom(response.data).then((sortedSections) => {
                        sectionsFromAPI = sortedSections;
                    });

                    return sectionsFromAPI;
                })
                .catch((error) => {
                    console.error(error.response);
                });
        } catch {
            console.log('catch');
        }

        return sectionsFromAPI;
    }

    /**
     *
     * @param sections
     * @returns
     */
    private static async filterSectionsByFrom(sections: Section[]): Promise<Section[] | null> {
        let sorted: Section[] | null = null;

        // if there are already authors in storage, we merge the array with the data from the server
        if (sections.length) {
            // filter out the authors that are updated
            const filteredSection = sections.filter(function (entry) {
                return !sections.some(function (elm: Section) {
                    return entry.id === elm.id;
                });
            });

            // merge filtered authors and the authors from the server
            sections = [...filteredSection, ...sections];
        }

        sorted = sections.sort((a, b) => a.from - b.from);

        return sorted;
    }
}
