<script lang="ts" setup>
import { onMounted } from 'vue';
import { session } from '@/store';

onMounted(() => {
    session.activeSplash = false;
});
</script>

<template>
    <main class="error-page">
        <h1>Ein feilur hendi</h1>
        <p>Vinarliga lat appina aftur og byrja av nýggjum.</p>
    </main>
</template>

<style lang="scss" scoped>
.error-page {
    display: flex;
    min-height: 100vh;
    padding: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 6rem;

    h1 {
        margin-bottom: 0;
    }
    h1,
    p {
        text-align: center;
    }
}
</style>
