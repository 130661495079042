import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "songs" }
const _hoisted_2 = { class: "songs-list" }
const _hoisted_3 = {
  class: "active-section font:serif weight:700",
  style: {"display":"none"}
}
const _hoisted_4 = {
  key: 0,
  class: "song-header font:serif"
}

import { Ref, ref, onMounted, onActivated, onDeactivated } from 'vue';
import SongListItem from '@/components/SongListItem.vue';
import { session } from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'List',
  setup(__props) {

const fontSize: number = session.fontSize;

const topHeight: Ref<number> = ref(fontSize * 23.125); // 370px

const calculateHeights = ref(() => {
    // Placeholder
});

// Scrolling
const loading: Ref<boolean> = ref(true);
const teleportLoading: Ref<boolean> = ref(true);

const showHeader: Ref<boolean> = ref(false);

onMounted(async () => {
    calculateHeights.value();

    loading.value = false;
    teleportLoading.value = false;
    setTimeout(() => {
        setSectionTitle();
    }, 1000);

    window.addEventListener('scroll', setSectionTitle);
});

onActivated(() => {
    teleportLoading.value = false;
});

onDeactivated(() => {
    teleportLoading.value = true;
});

let titles: any = [];
const activeSection = ref();

function setSectionTitle(): void {
    const song_elm = document.querySelectorAll('.song-item');

    let scroll_length = window.scrollY;

    song_elm.forEach((elm) => {
        let title = elm.getAttribute('section');
        if (isInViewport(elm) && !titles.includes(title)) {
            activeSection.value = title;
        } else {
            titles.splice(titles.indexOf(title));
        }
    });

    if (scroll_length > 330) {
        showHeader.value = true;
    } else {
        showHeader.value = false;
    }
}

function isInViewport(element: any) {
    const rect = element.getBoundingClientRect();
    return rect.top > -300 && rect.top < 40 && rect.left >= 0;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    (!teleportLoading.value)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "#top-content"
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["top-section", { visible: showHeader.value }])
          }, _toDisplayString(activeSection.value), 3)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("section", {
        class: "list-header font:serif",
        style: _normalizeStyle({ maxHeight: `${topHeight.value}px` })
      }, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "splash--title" }, [
          _createElementVNode("h1", null, "Nýggja sangbók"),
          _createElementVNode("p", null, "Guds fólks")
        ], -1)),
        _createElementVNode("div", _hoisted_3, _toDisplayString(activeSection.value), 1)
      ], 4),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(session).songs?.mobile, (song, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: `song-${index}`,
          class: "max-width"
        }, [
          (song.type === 'header')
            ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(song.section), 1))
            : _createCommentVNode("", true),
          (song.type === 'song')
            ? (_openBlock(), _createBlock(SongListItem, {
                key: 1,
                class: "song-item font:sans-serif",
                section: song.section,
                song: song
              }, null, 8, ["section", "song"]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}
}

})