import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "error-page" }

import { session } from '@/store';
import { onMounted } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NoConnection',
  setup(__props) {

onMounted(() => {
    session.activeSplash = false;
});

function retry() {
    location.reload();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h1", null, "Onki netsamband", -1)),
    _cache[2] || (_cache[2] = _createElementVNode("p", null, "Eindin hevur onki netsamband. Vinarliga tryggja tær, at eindin hevur net og royn aftur.", -1)),
    _createElementVNode("button", {
      class: "btn",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (retry()))
    }, " Royn aftur ")
  ]))
}
}

})