<script lang="ts" setup>
import { onActivated, ref } from 'vue';

import PageService from '@/services/Pages';

import VHtml from '@/components/VHtml.vue';

const page = ref();

onActivated(async () => {
    const data = await PageService.get('raettindi');

    page.value = data;
});
</script>

<template>
    <main class="rights font:sans-serif">
        <section class="px:30 max-width">
            <div
                v-if="page"
                class="page"
            >
                <h1 class="font:serif">{{ page.title }}</h1>

                <VHtml :content="page.content" />
            </div>
        </section>
    </main>
</template>

<style lang="scss" scoped>
.rights {
    padding-bottom: 92px;
}
</style>
