<script lang="ts" setup>
import { onMounted, defineProps, ref, Ref } from 'vue';

// Props

defineProps<{
    content: string;
}>();

// Data

const container: Ref<HTMLDivElement | undefined> = ref();

// Lifecycle Hooks

onMounted(() => {
    addTableWrapper();
});

// Functions

function addTableWrapper() {
    if (!container.value) {
        return;
    }

    const tables = container.value.querySelectorAll('table');

    for (const table of tables) {
        if (!table.parentNode) {
            continue;
        }

        const wrapper = document.createElement('div');

        wrapper.className = 'html-table';

        table.parentNode.insertBefore(wrapper, table);

        wrapper.appendChild(table);
    }
}
</script>

<template>
    <div
        ref="container"
        v-html="content"
        class="html"
    ></div>
</template>

<style lang="scss">
.html {
    figure {
        margin: 0 !important;
    }

    img {
        max-width: 100% !important;
        width: 100% !important;
    }

    .html-table {
        overflow-x: auto;

        margin: 0 -30px;

        font-size: 0.75rem;

        padding: 30px;

        th,
        td {
            padding: 8px;
            vertical-align: top;
            border-bottom: 1px solid #888888;
        }
    }

    table {
        border-collapse: collapse;
        vertical-align: top;

        thead {
            text-align: left;
            vertical-align: top;

            white-space: nowrap;
        }
    }
}
</style>
