import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "splash"
}

import { session } from '@/store';

import Loader from '@/components/Loader.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'VSplash',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_unref(session).activeSplash)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "splash--title" }, [
          _createElementVNode("h1", null, "Nýggja sangbók"),
          _createElementVNode("p", null, "Guds fólks")
        ], -1)),
        _createVNode(Loader),
        _cache[1] || (_cache[1] = _createElementVNode("p", { class: "splash-bottom font:serif" }, "Sálmabókagrunnurin", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "by-lunnar icon:lunnar" }, null, -1))
      ]))
    : _createCommentVNode("", true)
}
}

})