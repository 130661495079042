import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "index-songs" }
const _hoisted_2 = { class: "px:30 border-bottom max-width" }
const _hoisted_3 = { class: "links song-links max-width font:sans-serif" }

import { onMounted, Ref, ref } from 'vue';

import IndexSong from '@/components/IndexSong.vue';

import Songs from '@/types/songs';

import { session } from '@/store';

type Title = {
    is_choral: boolean;
    number: number;
    title: string;

    type: string;
};

// get each title of every song

export default /*@__PURE__*/_defineComponent({
  __name: 'IndexSongs',
  setup(__props) {

const songs: Songs | null = session.songs;
const titles: Ref<Title[]> = ref([]);

onMounted(() => {
    if (!songs) {
        return;
    }

    for (const song of songs.general) {
        for (const title of song.titles) {
            titles.value.push({
                ...title,
                number: song.number,
            });
        }
    }

    titles.value.sort((a, b) => (a.title > b.title ? 1 : -1));
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("h1", null, [
        _createVNode(_component_router_link, {
          to: { name: 'Index' },
          class: "back-icon"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("div", null, null, -1)
          ])),
          _: 1
        }),
        _cache[1] || (_cache[1] = _createTextVNode(" Yvirlit yvir sangir, niðurløg og kór "))
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "index-paragraph font:sans-serif" }, " Byrjanarreglurnar eru skrivaðar við vanligari skrift, niðurløgini við skráskrift, og kórini eru merkt við einum (k) ", -1))
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(titles.value, (song, index) => {
        return (_openBlock(), _createBlock(IndexSong, {
          key: `song-${index}`,
          song: song
        }, null, 8, ["song"]))
      }), 128))
    ])
  ]))
}
}

})