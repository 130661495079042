import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "index-sections" }
const _hoisted_2 = {
  key: 0,
  class: "links song-links max-width font:sans-serif"
}
const _hoisted_3 = {
  key: 1,
  class: "no-songs max-width font:sans-serif"
}

import { Ref, ref, onMounted, watch } from 'vue';

import SongListItem from '@/components/SongListItem.vue';

import Songs from '@/types/songs';
import Song from '@/types/song';

import { Store, session } from '@/store';

export default /*@__PURE__*/_defineComponent({
  __name: 'Favorite',
  setup(__props) {

const store = Store.getInstance();

const songs: Songs | null = session.songs;
const favouriteSongs: Ref<Song[]> = ref([]);

onMounted(() => {
    if (store.favorites.value.length > 0) {
        getFavorites(store.favorites.value);
    }
});

watch(store.favorites, (favorites) => {
    getFavorites(favorites);
});

function getFavorites(favorites: number[]) {
    favouriteSongs.value = [];

    favorites.forEach((favorite) => {
        const song = songs?.general.find((song) => Number(song.number) === favorite);

        if (song) {
            favouriteSongs.value.push(song);
        }
    });

    favouriteSongs.value.reverse();
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("section", {
      class: _normalizeClass(["title-with-back px:30 max-width", { 'border-bottom': favouriteSongs.value.length > 0 }])
    }, [
      _createVNode(_component_router_link, {
        to: { name: 'Menu' },
        class: "back-icon"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("div", null, null, -1)
        ])),
        _: 1
      }),
      _cache[1] || (_cache[1] = _createElementVNode("h1", null, "Yndissangir", -1))
    ], 2),
    (favouriteSongs.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(favouriteSongs.value, (song, index) => {
            return (_openBlock(), _createBlock(SongListItem, {
              key: `song${index}`,
              song: song
            }, null, 8, ["song"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (favouriteSongs.value.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[2] || (_cache[2] = [
          _createElementVNode("p", null, "Tú hevur ikki yndað nakran sang. Fyri at ynda sangir, skalt tú bert trýsta á nummarið á sanginum og velja “Ynda”.", -1)
        ])))
      : _createCommentVNode("", true)
  ]))
}
}

})