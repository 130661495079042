<script lang="ts" setup>
import { onMounted, ref, Ref, watch } from 'vue';

import { Preferences } from '@capacitor/preferences';

import { session } from '@/store';

import SongsService from '@/services/Songs';
import AuthorService from '@/services/Authors';
import SectionsService from '@/services/Sections';

import VSplash from '@/components/VSplash.vue';

onMounted(async () => {
    Preferences.get({ key: 'fontSize' }).then((res) => {
        const storedFontSize = Number(res.value || 16);

        session.fontSize = storedFontSize;
    });

    // Get base data
    const songsReady: Ref<boolean> = ref(false);
    const authorsReady: Ref<boolean> = ref(false);
    const sectionsReady: Ref<boolean> = ref(false);

    // Set songs
    if (!session.songs) {
        await SongsService.getSongs().then(() => {
            songsReady.value = true;
        });
    }
    // Set authors
    if (!session.authors) {
        await AuthorService.getAuthors().then(() => {
            authorsReady.value = true;
        });
    }
    // Set sections
    if (!session.sections) {
        await SectionsService.getSections().then(() => {
            sectionsReady.value = true;
        });
    }

    // hide splash screen if everything has loaded
    if (session.songs && session.authors && session.sections) {
        session.activeSplash = false;
    }

    // Populate the searchable array
    if (session.songs?.general) {
        const regex = /[-,.0-9]/g;

        session.searchable = [];

        let i = 0;

        for (const song of session.songs.general) {
            // Get first verse text, split by lines.
            const text = song.verses.reduce((previous: string[], current) => {
                return [
                    ...previous,
                    ...current.text
                        .replaceAll(regex, '')
                        .split('\n')
                        .map((line) => line.trim()),
                ];
            }, []);

            session.searchable.push({
                index: i++,
                number: song.number,
                type: song.type,
                titles: song.titles
                    .reduce((previous: string[], current) => [...previous, current.title], [])
                    .map((title) => title.replaceAll(regex, '')),
                text,
                isChoral: song.titles.some((title) => title.is_choral),
                authors: song.authors.map((author) => ({
                    name: author.first_name + ' ' + author.last_name,
                    firstName: author.first_name,
                    lastName: author.last_name,
                })),
            } as never);
        }
    }

    console.log(session.searchable);

    let resizeTimer: any;
    window.addEventListener('resize', () => {
        document.body.classList.add('resize-animation-stopper');

        clearTimeout(resizeTimer);

        resizeTimer = setTimeout(() => {
            document.body.classList.remove('resize-animation-stopper');
        }, 400);
    });
});

watch(
    () => session.fontSize,
    (fontSize) => {
        const html = document.querySelector('html');

        if (!html) {
            return;
        }

        html.style.fontSize = `${fontSize}px`;
    },
);
</script>

<template>
    <VSplash />

    <router-view
        v-slot="{ Component }"
        v-if="!session.activeSplash"
    >
        <transition :name="'slide-right'">
            <component :is="Component" />
        </transition>
    </router-view>
</template>

<style lang="scss" scoped>
.slide-right-enter-active {
    transition: all 0.3s ease;
    transition-delay: 0.1s;
}

.slide-right-leave-active {
    transition: all 0.1s ease;
}

.slide-right-enter-from {
    transform: translateX(50%);
    opacity: 0;
}

.slide-right-leave-to {
    opacity: 0;
}

.slide-left-enter-active {
    transition: all 0.3s ease;
    transition-delay: 0.1s;
}

.slide-left-leave-active {
    transition: all 0.1s ease;
}
.slide-left-enter-from {
    transform: translateX(-50%);
    opacity: 0;
}

.slide-left-leave-to {
    opacity: 0;
}
</style>
