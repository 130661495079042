<script lang="ts" setup>
import { Ref, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import SongListItem from '@/components/SongListItem.vue';

import Songs from '@/types/songs';
import Song from '@/types/song';
import Author from '@/types/author';

import { session } from '@/store';

const route = useRoute();
const songs: Songs | null = session.songs;
const authors: Author[] | null = session.authors;

const author: Ref<Author | null> = ref(null);
const authorSongs: Ref<Song[]> = ref([]);
const loading: Ref<boolean> = ref(true);

onMounted(() => {
    if (route.params.id) {
        const findAuthor = authors?.find((findAuthor: Author) => findAuthor.id === Number(route.params.id));

        if (findAuthor && songs) {
            author.value = findAuthor;

            const findAuthorSongs: Song[] = [];

            findAuthor.songs.forEach((song: number) => {
                findAuthorSongs.push(songs.general[song - 1]);
            });

            authorSongs.value = findAuthorSongs;
        }
    }

    setTimeout(() => {
        loading.value = false;
    }, 200);
});
</script>

<template>
    <main v-if="author">
        <section class="px:30 border-bottom max-width">
            <h1>{{ author.key }}</h1>
        </section>

        <div class="links song-links max-width font:sans-serif">
            <song-list-item
                v-for="(song, index) in authorSongs"
                :key="`song${index}`"
                :song="song"
            />
        </div>
    </main>
</template>
