<script lang="ts" setup>
import { Ref, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import Song from '@/components/Song.vue';

import SongType from '@/types/song';
import Songs from '@/types/songs';

import { session } from '@/store';

const route = useRoute();
const song: Ref<SongType | null> = ref(null);
const songs: Songs | null = session.songs;

onMounted(() => {
    if (route.params.id && songs) {
        song.value = songs.general[Number(route.params.id) - 1];
    }
});
</script>

<template>
    <main class="index-sections">
        <section class="px:30 max-width">
            <router-link
                :to="{ name: 'Songs' }"
                class="back-icon"
            >
                <div></div>
            </router-link>
            <h1>Akkordir</h1>
        </section>

        <Song
            :song="song || undefined"
            :chords="true"
        />
    </main>
</template>

<style lang="scss" scoped>
.back-icon {
    position: absolute;
    left: -4.5rem;
    top: -0.4rem;
}
.max-width {
    position: relative;
    max-width: 23rem;
}
.song {
    @media (min-width: 768px) {
        max-width: 23rem;
        margin: 0 auto;
        padding: 0;
    }
}
</style>
