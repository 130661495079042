import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "index" }
const _hoisted_2 = { class: "title-with-back border-bottom px:30 max-width" }

import Links from '@/components/Links.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

const links = [
    { title: 'Yrkjarar, týðarar og tónaskøld', url: '/menu/index/people' },
    { title: 'Deildir', url: '/menu/index/sections' },
    { title: 'Yvirlit yvir sangir, niðurløg og kór', url: '/menu/index/songs' },
];

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: { name: 'Menu' },
        class: "back-icon"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("div", null, null, -1)
        ])),
        _: 1
      }),
      _cache[1] || (_cache[1] = _createElementVNode("h1", null, "Innihaldsyvirlit", -1))
    ]),
    _createVNode(Links, {
      class: "max-width",
      links: links
    })
  ]))
}
}

})