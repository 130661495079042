import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "tablet-songs-wrapper" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "song-meta-container" }
const _hoisted_4 = { class: "song-number" }
const _hoisted_5 = { class: "song-meta" }
const _hoisted_6 = { class: "song-context" }
const _hoisted_7 = {
  key: 0,
  class: "song-context--like-btn icon"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["innerHTML"]

import { PropType } from 'vue';
import Song from '@/types/song';
import { Store } from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'TabletSongs',
  props: {
    songs: Array as PropType<Song[]>,
    chords: Boolean,
},
  emits: ['context'],
  setup(__props, { emit: __emit }) {

const store = Store.getInstance();

const props = __props;

const emit = __emit;

function isFavorite(number: any) {
    if (!number) {
        return false;
    }

    return store.favorites.value.includes(Number(number));
}

function openContextMenu(number: any) {
    emit('context', number);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.songs, (song) => {
      return (_openBlock(), _createElementBlock("div", {
        key: song.number,
        class: "song px:30"
      }, [
        _createElementVNode("div", {
          class: "song-top mb:10",
          onClick: ($event: any) => (openContextMenu(song?.number))
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(song?.number), 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(song?.meta), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            (isFavorite(song?.number))
              ? (_openBlock(), _createElementBlock("div", _hoisted_7))
              : _createCommentVNode("", true),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "song-context--menu-btn" }, null, -1))
          ])
        ], 8, _hoisted_2),
        _createElementVNode("div", {
          class: _normalizeClass(["song-content", { 'font:mono': __props.chords }])
        }, [
          (!__props.chords)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(song?.verses, (verse, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `verse${index}`,
                  class: _normalizeClass(verse.type),
                  onClick: ($event: any) => (_ctx.$emit('context-menu', { type: 'content', data: verse }))
                }, _toDisplayString(verse.text), 11, _hoisted_8))
              }), 128))
            : _createCommentVNode("", true),
          (__props.chords && song?.chords)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                innerHTML: song?.chords
              }, null, 8, _hoisted_9))
            : _createCommentVNode("", true)
        ], 2)
      ]))
    }), 128))
  ]))
}
}

})