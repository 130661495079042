import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "song px:30" }
const _hoisted_2 = { class: "song-meta-container" }
const _hoisted_3 = { class: "song-number" }
const _hoisted_4 = { class: "song-meta" }
const _hoisted_5 = { class: "song-context" }
const _hoisted_6 = {
  key: 0,
  class: "song-context--like-btn icon"
}
const _hoisted_7 = ["innerHTML"]

import { PropType } from 'vue';
import Song from '@/types/song';
import { Store } from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'Song',
  props: {
    song: Object as PropType<Song>,
    chords: Boolean,
},
  emits: ['context'],
  setup(__props, { emit: __emit }) {

const store = Store.getInstance();

const props = __props;

const emit = __emit;

function isFavorite(number: any) {
    if (!number) {
        return false;
    }

    return store.favorites.value.includes(Number(number));
}

function openContextMenu(number: any) {
    emit('context', number);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "song-top mb:10",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (openContextMenu(props.song?.number)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(props.song?.number), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(props.song?.meta), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (isFavorite(props.song?.number))
          ? (_openBlock(), _createElementBlock("div", _hoisted_6))
          : _createCommentVNode("", true),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "song-context--menu-btn" }, null, -1))
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["song-content", { 'font:mono': __props.chords }])
    }, [
      (!__props.chords)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(__props.song?.verses, (verse, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `verse${index}`,
              class: _normalizeClass(verse.type)
            }, _toDisplayString(verse.text), 3))
          }), 128))
        : _createCommentVNode("", true),
      (__props.chords && props.song?.chords)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            innerHTML: props.song?.chords
          }, null, 8, _hoisted_7))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}
}

})