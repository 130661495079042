<script lang="ts" setup>
import { onMounted, Ref, ref, watch } from 'vue';

import { useRoute } from 'vue-router';

import Fuse from 'fuse.js';

import { session } from '@/store';

const route = useRoute();

const fuse = new Fuse([], {
    threshold: 0.2,
    distance: Infinity,
    includeScore: true,
    shouldSort: true,
    keys: [
        {
            name: 'titles',
            weight: 5,
        },
        'number',
        'authors.name',
        'authors.firstName',
        'authors.lastName',
        'text',
    ],
});

const isSearching: Ref<boolean> = ref(false);
const hits: Ref<any[]> = ref([]);
const query: Ref<string> = ref('');
const loading: Ref<boolean> = ref(false);

// Set Fuse collection

onMounted(() => {
    fuse.setCollection(session.searchable);
});

/**
 * Whenever 'query' changes, (fuzzy) search for songs
 */
let timeout = 0;
watch(query, (query) => {
    // debounce
    clearTimeout(timeout);

    const regex = /[-,.]/g;

    query = query.replaceAll(regex, '');

    loading.value = true;

    timeout = setTimeout(() => {
        hits.value = [];

        const results = fuse.search(query);

        hits.value = results.map((hit) => hit.item).slice(0, 50); // cap to 50 items

        loading.value = false;
    }, 250);
});

watch(route, () => {
    setTimeout(() => {
        isSearching.value = false;
        query.value = '';
        hits.value = [];
    }, 1000);
});

function shouldBlur() {
    if (query.value.length !== 0) {
        return;
    }

    isSearching.value = false;
}

function clearQuery() {
    query.value = '';
    isSearching.value = false;
}

function getTitles(songIndex: number): any[] {
    const song = session.songs?.general[songIndex];

    if (!song) {
        return [];
    }

    return song.titles;
}
</script>

<template>
    <main class="search">
        <section
            class="max-width px:30"
            :class="{ 'border-bottom': query.length >= 3 && hits?.length }"
        >
            <h1
                class="transition"
                :class="{ hidden: isSearching }"
            >
                Leita
            </h1>
            <div class="search-input">
                <input
                    type="text"
                    @focus="isSearching = true"
                    @blur="shouldBlur"
                    placeholder="Leita..."
                    v-model="query"
                />

                <div
                    v-if="query.length === 0"
                    class="icon:search"
                ></div>
                <div
                    @click="clearQuery"
                    v-if="query.length > 0"
                    class="icon:close"
                ></div>
            </div>
        </section>

        <div class="links song-links font:sans-serif max-width">
            <template
                v-for="(hit, index) in hits"
                :key="`song-${index}`"
            >
                <router-link :to="`/songs?song=${hit?.number}`">
                    <div class="song-link--name">
                        {{ getTitles(hit.index)?.[0]?.title }}
                    </div>

                    <div class="song-link--number">
                        <span v-if="hit.isChoral">(k)</span>

                        <span class="number">{{ hit?.number }}</span>
                    </div>
                </router-link>
            </template>
        </div>

        <p
            v-if="query.length < 3 && !hits?.length"
            class="search-paragraph px:45 max-width text:center text:sm font:sans-serif"
        >
            Nýt leitifunkuna at leita eftir sangum, niðurløgum ella kórum. Eisini kann leitast eftir yrkjara, týðara og meira.
        </p>

        <p
            v-if="query.length > 2 && !hits?.length && !loading"
            class="my:40 px:45 text:center text:sm max-width font:sans-serif"
        >
            Onki varð funnið
        </p>
    </main>
</template>

<style lang="scss" scoped>
@import '@/scss/mixins';

.search-paragraph {
    margin-top: 0.5rem;
    line-height: 150%;

    @media (min-width: 992px) {
        margin-top: 4rem;
        max-width: 17rem;
    }
}

.transition {
    transition: 0.1s ease;
}

.hidden {
    max-height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
}

.search-input {
    position: relative;
    margin-bottom: 2rem;

    [class*='icon\:'] {
        display: block;

        position: absolute;
        top: 0;
        right: 0.375rem;
        width: 3.75rem;
        height: 3.75rem;

        background: #8a8553;

        transition: 0.2s background ease;

        &:hover {
            background: darken(#8a8553, 10%);
        }
    }

    .icon\:search {
        @include mask('../assets/icon-search.svg', 1.25rem 1.25rem);
        pointer-events: none;
    }

    .icon\:close {
        @include mask('../assets/icon-close.svg', 1.25rem 1.25rem);
    }
}
</style>
