import { reactive, ref, Ref, watch } from 'vue';
import { Preferences } from '@capacitor/preferences';
import Songs from './types/songs';
import Song from './types/song';
import Author from './types/author';
import Section from './types/section';
import Heights from './types/heights';
import PouchDoc from './types/pouchDoc';

import PouchDB from 'pouchdb';

type ContextMenu = {
    open: boolean;
    song: Song | null;
};

export class DB {
    private static instance: DB;
    private db: PouchDB.Database<any>;
    private authorsDB: PouchDB.Database<any>;
    private sectionsDB: PouchDB.Database<any>;
    private settings: PouchDB.Database<any>;

    public constructor() {
        this.db = new PouchDB('songs');
        this.authorsDB = new PouchDB('authors');
        this.sectionsDB = new PouchDB('sections');
        this.settings = new PouchDB('settings');
    }

    public static getInstance(): DB {
        if (!this.instance) {
            this.instance = new this();
        }

        return this.instance;
    }

    async deleteDB() {
        // remove last sync time
        Preferences.remove({
            key: 'songs_time',
        });
        Preferences.remove({
            key: 'authors_time',
        });
        Preferences.remove({
            key: 'sections_time',
        });

        this.db
            .destroy()
            .then(function (response) {
                console.log('DB deleted');
                response;
                // success
            })
            .catch(function (err) {
                console.log(err);
            });

        this.authorsDB
            .destroy()
            .then(function (response) {
                console.log('Authors DB deleted');
                response;
            })
            .catch(function (err) {
                console.log(err);
            });

        this.sectionsDB
            .destroy()
            .then(function (response) {
                console.log('Sections DB deleted');
                response;
            })
            .catch(function (err) {
                console.log(err);
            });

        this.settings
            .destroy()
            .then(function (response) {
                console.log('DB deleted');
                response;
                // success
            })
            .catch(function (err) {
                console.log(err);
            });

        return;
    }

    async saveSongs(songs: Song[]) {
        return this.db
            .bulkDocs(songs)
            .then(function (result) {
                // handle result
                return result;
            })
            .catch(function (err) {
                console.log(err);
            });
    }

    async updateSongs(songs: Song[]) {
        console.log('DB: Update Songs');

        for (const song of songs) {
            // get song from db
            await this.db
                .get(song._id)
                .then(async (doc) => {
                    // handle doc
                    console.log('doc to update', doc);

                    song._rev = doc._rev;
                })
                .catch(function (err) {
                    console.log(err);
                });

            // save updated song
            await this.db
                .put(song)
                .then(async () => {
                    console.log('Song updated: ', song.titles[0]);
                    // handle response
                })
                .catch(function (err) {
                    console.log(err);
                });
        }
    }

    async getSongs(): Promise<Song[]> {
        return this.db
            .allDocs({
                include_docs: true,
                attachments: true,
            })
            .then(async (result) => {
                // handle result
                if (!result.rows) {
                    return;
                }
                const songsToReturn: any = [];
                for (const elm of result.rows) {
                    songsToReturn.push(elm.doc);
                }

                return songsToReturn;
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async saveRenderedSongs(songs: Songs) {
        // delete the old db
        await this.settings
            .get('renderedSongs')
            .then((doc) => {
                return this.settings.remove(doc);
            })
            .then(() => {
                // handle result
            })
            .catch(function (err) {
                console.log(err);
            });

        // create doc to save
        const put: PouchDoc = {
            _id: 'renderedSongs',
            data: songs,
        };

        // save rendered songs to settings db and return
        this.settings
            .put(put)
            .then(() => {
                // handle response
            })
            .catch(function (err) {
                console.log(err);
            });
    }

    async getRenderedSongs(): Promise<Songs | null> {
        return this.settings
            .get('renderedSongs')
            .then(async (doc) => {
                if (doc) {
                    return doc.data;
                }
            })
            .catch(function () {
                //console.log(err);
                return;
            });
    }

    async saveAuthors(authors: Author[]) {
        return this.authorsDB
            .bulkDocs(authors)
            .then(function (result) {
                // handle result
                return result;
            })
            .catch(function (err) {
                console.log(err);
            });
    }

    async updateAuthors(authors: Author[]) {
        console.log('DB: Update Authors');

        for (const author of authors) {
            // get song from db
            await this.authorsDB
                .get(author._id)
                .then(async (doc) => {
                    // handle doc
                    console.log('doc to update', doc);

                    author._rev = doc._rev;
                })
                .catch(function (err) {
                    console.log(err);
                });

            // save updated song
            await this.authorsDB
                .put(author)
                .then(async () => {
                    console.log('Author updated: ', author.first_name);
                    // handle response
                })
                .catch(function (err) {
                    console.log(err);
                });
        }
    }

    async getAuthors(): Promise<Author[]> {
        return this.authorsDB
            .allDocs({
                include_docs: true,
                attachments: true,
            })
            .then(async (result) => {
                // handle result
                if (!result.rows) {
                    return;
                }
                const authorsToReturn: any = [];
                for (const elm of result.rows) {
                    authorsToReturn.push(elm.doc);
                }

                return authorsToReturn;
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async saveSections(sections: Section[]) {
        return this.sectionsDB
            .bulkDocs(sections)
            .then(function (result) {
                // handle result
                return result;
            })
            .catch(function (err) {
                console.log(err);
            });
    }

    async updateSections(sections: Section[]) {
        console.log('DB: Update Sections');

        for (const section of sections) {
            // get song from db
            await this.sectionsDB
                .get(section._id)
                .then(async (doc) => {
                    // handle doc
                    console.log('doc to update', doc);

                    section._rev = doc._rev;
                })
                .catch(function (err) {
                    console.log(err);
                });

            // save updated song
            await this.sectionsDB
                .put(section)
                .then(async () => {
                    console.log('Section updated: ', section.title);
                    // handle response
                })
                .catch(function (err) {
                    console.log(err);
                });
        }
    }

    async getSections(): Promise<Section[]> {
        return this.sectionsDB
            .allDocs({
                include_docs: true,
                attachments: true,
            })
            .then(async (result) => {
                // handle result
                if (!result.rows) {
                    return;
                }
                const sectionsToReturn: any = [];
                for (const elm of result.rows) {
                    sectionsToReturn.push(elm.doc);
                }

                return sectionsToReturn;
            })
            .catch((err) => {
                console.log(err);
            });
    }
}

export const session = reactive({
    activeSplash: true as boolean,
    device: 'mobile' as string,
    contextMenu: { open: false, song: null } as ContextMenu,

    fontSize: 16 as number,

    songs: null as Songs | null,
    authors: null as Author[] | null,
    sections: null as Section[] | null,

    searchable: [] as never[],
});

export class Store {
    private static instance: Store;

    public fontSize: Ref<number> = ref(16);
    public songsHeights: Ref<Heights[]> = ref([]);
    public listHeights: Ref<Heights[]> = ref([]);

    public favorites: Ref<number[]> = ref([]);

    // constructor
    private constructor() {
        this.addToStorage(this.favorites, 'favorites');
        this.addToStorage(this.fontSize, 'fontSize');
        this.addToStorage(this.songsHeights, 'songsHeights');
        this.addToStorage(this.listHeights, 'listHeights');
    }

    public static getInstance(): Store {
        if (!this.instance) {
            this.instance = new this();
        }

        return this.instance;
    }

    private addToStorage(reference: Ref<any>, key: string): void {
        watch(reference, async (value) => {
            await Preferences.set({
                key,
                value: JSON.stringify(value),
            });
        });

        (async () => {
            const favorites = await Preferences.get({ key });

            if (!favorites.value) {
                return;
            }

            reference.value = JSON.parse(favorites.value);
        })();
    }
}
