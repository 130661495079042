import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "top-header" }

import { ref, Ref, watch } from 'vue';

import { useRoute } from 'vue-router';

// Data


export default /*@__PURE__*/_defineComponent({
  __name: 'VHeader',
  setup(__props) {

const route = useRoute();

const path: Ref<any> = ref({ name: route.meta?.link });

// Watchers

watch(route, (routeValue) => {
    if (route.query.from) {
        const query = route.query.from.toString();

        const [type, number] = query.split('-');

        if (type == 'song') {
            path.value = { name: 'Songs', query: { song: number } };
        }

        return;
    }

    if (routeValue.meta.link) {
        path.value = { name: routeValue.meta.link };

        return;
    }

    path.value = '';
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createElementVNode("div", _hoisted_1, [
      (path.value)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: path.value,
            class: "back-icon"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("div", null, null, -1)
            ])),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true),
      _cache[1] || (_cache[1] = _createElementVNode("div", { id: "top-content" }, null, -1))
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "header-gradient" }, null, -1))
  ]))
}
}

})